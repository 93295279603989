import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as Mapboxgl from 'mapbox-gl';
import { Subject, forkJoin } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { EventoService } from '../../../services/evento.service';
import { GoogleAnalytics } from '../../../shared/google-analytics';
import * as turf from '@turf/turf';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'app-event-details',
    templateUrl: './event-details.component.html',
    styleUrls: ['./event-details.component.scss']
})
export class EventDetailsComponent implements OnInit {
    public id = 0;
    public evento: any;
    public desafios: any;
    private compDestroy: Subject<boolean> = new Subject();
    public loading = false;
    mapa: Mapboxgl.Map;
    marker: Mapboxgl.Marker;
    selectedPosition: Mapboxgl.LngLat;
    public preventas: any;
    @ViewChild('mapaRuta', { static: true }) mapaRuta: ElementRef;


    constructor(
        private activatedroute: ActivatedRoute,
        private eventoService: EventoService,
        private googleAnalytics: GoogleAnalytics,
        private datePipe: DatePipe,
        private sanitizer: DomSanitizer


    ) {
        this.activatedroute.queryParams.subscribe(params => {
            this.id = Number(params.id);
        });
    }

    ngOnInit(): void {
        this.googleAnalytics.eventEmitter('detalle-evento', 'exitoso', '', 'click', 0);

        this.getDataDefault();
        Mapboxgl.accessToken = environment.tokenMapbox;


    }

    getDataDefault() {
        this.loading = true;
        const obtenerEvento = this.eventoService.obtenerEventoById(this.id);
        const obtenerDesafiosPorEventoId = this.eventoService.obtenerDesafiosPorEventoId(this.id);
        const obtenerPreventasPorEvento = this.eventoService.obtenerPreventasPorEvento(this.id);

        const petitions = forkJoin([obtenerEvento, obtenerDesafiosPorEventoId, obtenerPreventasPorEvento]);

        petitions
            .pipe(take(1), takeUntil(this.compDestroy))
            .subscribe(
                res => {
                    this.evento = res[0];
                    this.desafios = res[1];
                    this.preventas = res[2];
                    this.loading = false;

                    this.configuracionMapa();
                    if (this.evento.mostrarRuta === 'ACTIVADO') {
                        if (this.evento.tipoRuta === 'CON_ALTURA') {
                            this.configuracionMapaRutaConAltura();

                        }

                        if (this.evento.tipoRuta === 'SEGUIMIENTO') {
                            this.configuracionMapaRutaSeguimiento();

                        }
                    } else {
                        const elementoNative = this.mapaRuta.nativeElement as HTMLElement;

                        // Cambiar los estilos
                        elementoNative.style.display = 'none';
                    }
                },
                err => {
                    console.log(err);
                    this.loading = false;
                });
    }

    ordenarCategorias(desafio: string): [] {
        if(this.evento.opcion_federado === 'FEDERADO'){
            return this.evento.categorias.sort((a, b) => `${a.nombre} - ${a.sexo}` > `${b.nombre} - ${b.sexo}` ? 1 : -1).filter((categoria) => categoria.desafioEntity.nombre === desafio && categoria.federada === 'ACTIVADA');
        }

        if(this.evento.opcion_federado === 'NO_FEDERADO' || this.evento.opcion_federado === 'AMBAS_OPCIONES'){
            return this.evento.categorias.sort((a, b) => `${a.nombre} - ${a.sexo}` > `${b.nombre} - ${b.sexo}` ? 1 : -1).filter((categoria) => categoria.desafioEntity.nombre === desafio && categoria.federada === 'DESACTIVADA');
        }
    }

    getDayNumber(fechaEvento) {
        const date = this.datePipe.transform(fechaEvento, 'yyyy-MM-dd', 'UTC');
        return date.toString().split('-')[2];
    }

    getMonthSpanish(date) {
        switch (Number(date.toString().split('-')[1])) {
            case 1:
                return 'Enero';
            case 2:
                return 'Febrero';
            case 3:
                return 'Marzo';
            case 4:
                return 'Abril';
            case 5:
                return 'Mayo';
            case 6:
                return 'Junio';
            case 7:
                return 'Julio';
            case 8:
                return 'Agosto';
            case 9:
                return 'Septiembre';
            case 10:
                return 'Octubre';
            case 11:
                return 'Noviembre';
            case 12:
                return 'Diciembre';
        }
    }

    getEnvironmentAdSense() {
        return environment.adSenseClient;
    }

    cantidadEventosAdicionalesValidos() {
        return this.evento.eventoAdicionales.filter((eventoAdicional) => eventoAdicional.cupos > eventoAdicional.vendidos
            && eventoAdicional.disponible === 'ACTIVADO').length;
    }

    configuracionMapa() {
        this.mapa = new Mapboxgl.Map({
            container: 'mapa-mapbox', // container ID
            // Choose from Mapbox's core styles, or make your own style with Mapbox Studio
            style: environment.styleMapbox, // style URL
            center: [Number(this.evento.longitud), Number(this.evento.latitud)], // starting position
            zoom: environment.zoomMapbox // starting zoom
        });

        this.selectedPosition = new Mapboxgl.LngLat(Number(this.evento.longitud), Number(this.evento.latitud));
        this.addMarker();
    }

    async configuracionMapaRutaConAltura() {
        let mapaRuta;

        mapaRuta = new Mapboxgl.Map({
            container: 'mapa-mapbox-ruta', // container ID
            // Choose from Mapbox's core styles, or make your own style with Mapbox Studio
            style: environment.styleMapbox, // style URL
            center: [Number(this.evento.longitudCenter), Number(this.evento.latitudCenter)], // starting position
            zoom: this.evento.zoom, // starting zoom
            pitch: 76,
            bearing: 10,
            interactive: false,
            hash: false
        });

        // Start downloading the route data, and wait for map load to occur in parallel
        const [pinRouteGeojson] = await Promise.all([
            fetch(
                this.evento.urlGSON
            ).then((response) => response.json()),
            mapaRuta.once('style.load')
        ]);


        // Add terrain source, with slight exaggeration
        mapaRuta.addSource('mapbox-dem', {
            'type': 'raster-dem',
            'url': 'mapbox://mapbox.terrain-rgb',
            'tileSize': 512,
            'maxzoom': 14
        });
        mapaRuta.setTerrain({ 'source': 'mapbox-dem', 'exaggeration': 1.5 });
        const pinRoute = pinRouteGeojson.features[0].geometry.coordinates;
        // Create the marker and popup that will display the elevation queries
        const popup = new Mapboxgl.Popup({ closeButton: false });
        const marker = new Mapboxgl.Marker({
            color: 'red',
            scale: 0.8,
            draggable: false,
            pitchAlignment: 'auto',
            rotationAlignment: 'auto'
        })
            .setLngLat(pinRoute[0])
            .setPopup(popup)
            .addTo(mapaRuta)
            .togglePopup();

        // Add a line feature and layer. This feature will get updated as we progress the animation
        mapaRuta.addSource('line', {
            type: 'geojson',
            // Line metrics is required to use the 'line-progress' property
            lineMetrics: true,
            data: pinRouteGeojson
        });
        mapaRuta.addLayer({
            type: 'line',
            source: 'line',
            id: 'line',
            paint: {
                'line-color': 'rgba(0,0,0,0)',
                'line-width': 5
            },
            layout: {
                'line-cap': 'round',
                'line-join': 'round'
            }
        });

        await mapaRuta.once('idle');
        // The total animation duration, in milliseconds
        const animationDuration = this.evento.duracionMsRuta;
        // Use the https://turfjs.org/ library to calculate line distances and
        // sample the line at a given percentage with the turf.along function.
        const path = turf.lineString(pinRoute);
        // Get the total line distance
        const pathDistance = turf.lineDistance(path);
        let start;
        function frame(time) {
            if (!start) start = time;
            const animationPhase = (time - start) / animationDuration;
            if (animationPhase > 1) {
                return;
            }

            // Get the new latitude and longitude by sampling along the path
            const alongPath = turf.along(path, pathDistance * animationPhase)
                .geometry.coordinates;
            const lngLat = {
                lng: alongPath[0],
                lat: alongPath[1]
            };

            // Sample the terrain elevation. We round to an integer value to
            // prevent showing a lot of digits during the animation
            const elevation = Math.floor(
                // Do not use terrain exaggeration to get actual meter values
                mapaRuta.queryTerrainElevation(lngLat, { exaggerated: false })
            );

            // Update the popup altitude value and marker location
            popup.setHTML('Altitud: ' + elevation + 'm<br/>');
            marker.setLngLat(lngLat);

            // Reduce the visible length of the line by using a line-gradient to cutoff the line
            // animationPhase is a value between 0 and 1 that reprents the progress of the animation
            mapaRuta.setPaintProperty('line', 'line-gradient', [
                'step',
                ['line-progress'],
                'red',
                animationPhase,
                'rgba(255, 0, 0, 0)'
            ]);

            // Rotate the camera at a slightly lower speed to give some parallax effect in the background
            const rotation = 150 - animationPhase * 40.0;
            mapaRuta.setBearing(rotation % 360);

            window.requestAnimationFrame(frame);
        }

        window.requestAnimationFrame(frame);


    }

    async configuracionMapaRutaSeguimiento() {
        let mapaRuta;

        mapaRuta = new Mapboxgl.Map({
            container: 'mapa-mapbox-ruta', // container ID
            center: [Number(this.evento.longitudCenter), Number(this.evento.latitudCenter)], // starting position
            zoom: this.evento.zoom, // starting zoom
            pitch: 65,
            bearing: -180,
            style: environment.styleMapbox, // style URL
            interactive: false
        });

        // Start downloading the route data, and wait for map load to occur in parallel
        const [pinRouteGeojsonSegimiento] = await Promise.all([
            fetch(
                this.evento.urlGSON
            ).then((response) => response.json()),
            mapaRuta.once('style.load')
        ]);

        const targetRoute = pinRouteGeojsonSegimiento.features[0].geometry.coordinates;
        // this is the path the camera will move along
        const cameraRoute = pinRouteGeojsonSegimiento.features[0].geometry.coordinates;


        
        mapaRuta.addSource('mapbox-dem', {
            'type': 'raster-dem',
            'url': 'mapbox://mapbox.terrain-rgb',
            'tileSize': 512,
            'maxzoom': 14
        });
        mapaRuta.setTerrain({ 'source': 'mapbox-dem', 'exaggeration': 1.5 });
        mapaRuta.addSource('trace', {
            type: 'geojson',
            data: {
                'type': 'Feature',
                'properties': {},
                'geometry': {
                    'type': 'LineString',
                    'coordinates': targetRoute
                }
            }
        });



        mapaRuta.addLayer({
            type: 'line',
            source: 'trace',
            id: 'line',
            paint: {
                'line-color': 'red',
                'line-width': 5
            },
            layout: {
                'line-cap': 'round',
                'line-join': 'round'
            }
        });

        const animationDuration = this.evento.duracionMsRuta;
        const cameraAltitude = 2000;
        // get the overall distance of each route so we can interpolate along them
        const routeDistance = turf.lineDistance(turf.lineString(targetRoute));
        const cameraRouteDistance = turf.lineDistance(
            turf.lineString(cameraRoute)
        );

        let start;

        function frame(time) {
            if (!start) start = time;
            // phase determines how far through the animation we are
            const phase = (time - start) / animationDuration;
            
            // phase is normalized between 0 and 1
            // when the animation is finished, reset start to loop the animation
            if (phase > 1) {
                // wait 1.5 seconds before looping
                setTimeout(() => {
                    start = 0.0;
                }, 1500);
            }

            // use the phase to get a point that is the appropriate distance along the route
            // this approach syncs the camera and route positions ensuring they move
            // at roughly equal rates even if they don't contain the same number of points
            const alongRoute = turf.along(
                turf.lineString(targetRoute),
                routeDistance * phase
            ).geometry.coordinates;

            const alongCamera = turf.along(
                turf.lineString(cameraRoute),
                cameraRouteDistance * phase * 0.7
            ).geometry.coordinates;

            const camera = mapaRuta.getFreeCameraOptions();

            // set the position and altitude of the camera
            camera.position = Mapboxgl.MercatorCoordinate.fromLngLat(
                {
                    lng: alongCamera[0],
                    lat: alongCamera[1]
                },
                cameraAltitude
            );

            // tell the camera to look at a point along the route
            camera.lookAtPoint({
                lng: alongRoute[0],
                lat: alongRoute[1]
            });

            mapaRuta.setFreeCameraOptions(camera);

            window.requestAnimationFrame(frame);
        }

        window.requestAnimationFrame(frame);

    }

    addMarker() {
        if (this.marker) {
            this.marker.remove();
        }

        this.marker = new Mapboxgl.Marker()
            .setLngLat(this.selectedPosition)
            .addTo(this.mapa);
    }

    round(number) {
        return Math.round(number);
    }

    obtenerMultiplicadorPreventa(porcentajeAumento) {
        return 1 + (porcentajeAumento / 100);
    }

    sanitizerUrl(url){
        return this.sanitizer.bypassSecurityTrustStyle(`url("${url}")`);
    }
}
