import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {UsuarioDeportistaService} from '../../../services/usuario-deportista.service';
import {Observable, Subject} from 'rxjs';
import {take, takeUntil} from 'rxjs/operators';
import Swal from 'sweetalert2';
import {Router} from '@angular/router';
import {EncryptService} from '../../../shared/encrypt';
import {UsuarioOrganizadorService} from '../../../services/usuario-organizador.service';
import {GoogleAnalytics} from "../../../shared/google-analytics";

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    public formLogin: FormGroup ;
    public loading = false;
    private compDestroy: Subject<boolean> = new Subject();

    constructor(
        private fb: FormBuilder,
        private usuarioDeportistaService: UsuarioDeportistaService,
        private usuarioOrganizadorService: UsuarioOrganizadorService,
        private router: Router,
        private encryptService: EncryptService,
        private googleAnalytics: GoogleAnalytics

    ) { }

    ngOnInit(): void {
        this.googleAnalytics.eventEmitter('login', 'exitoso', '', 'click', 0);

        this.initForm();

    }

    initForm() {
        this.formLogin = this.fb.group({
            email: ['', Validators.required],
            password: ['', Validators.required]
        });
    }

    login(){
        if (!this.formLogin.valid){
            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger'
                },
                buttonsStyling: false
            });

            swalWithBootstrapButtons.fire(
                'Uppps....!',
                'Te faltan datos que agregar',
                'error'
            );
            return;
        }

        this.loading = true;

        const petition = this.usuarioDeportistaService.login(this.formLogin.value);
        const petitionLoginAdmin = this.usuarioOrganizadorService.login(this.formLogin.value);

        petition
            .pipe(take(1), takeUntil(this.compDestroy))
            .subscribe(
                res => {
                    this.getDataUser(res.id, res);

                },
                err => {
                    if (err.error.includes('email must be an email')){
                        const swalWithBootstrapButtons = Swal.mixin({
                            customClass: {
                                confirmButton: 'btn btn-success',
                                cancelButton: 'btn btn-danger'
                            },
                            buttonsStyling: false
                        });

                        swalWithBootstrapButtons.fire(
                            'Uppps....!',
                            'Debes ingresar un correo electrónico con el formato xxxxxx@xxxxxx.xxx',
                            'error'
                        );
                    }

                    petitionLoginAdmin
                        .pipe(take(1), takeUntil(this.compDestroy))
                        .subscribe(
                            res => {
                                this.getDataUser(res.id, res);

                            },
                            error => {
                                if (error.error.includes('Invalid username/password')){
                                    const swalWithBootstrapButtons = Swal.mixin({
                                        customClass: {
                                            confirmButton: 'btn btn-success',
                                            cancelButton: 'btn btn-danger'
                                        },
                                        buttonsStyling: false
                                    });

                                    swalWithBootstrapButtons.fire(
                                        'Uppps....!',
                                        'Correo electrónico/contraseña inválida.',
                                        'error'
                                    );
                                }
                                this.loading = false;
                            });



                });
    }

    getDataUser(userId: number, res: any) {
        sessionStorage.setItem('token', this.encryptService.encryptData(res.token));

        if (res.role === 'Organizador'){
            this.usuarioOrganizadorService.obtenerUsuarioOrganizadorById(userId).subscribe(
                dataUser => {
                    this.loading = false;

                    // guardamos data user en localStorage para persistir data
                    sessionStorage.setItem('dataUser', this.encryptService.encryptData(JSON.stringify(res)));
                    sessionStorage.setItem('dataUserComplete', this.encryptService.encryptData(JSON.stringify(dataUser)));
                    sessionStorage.setItem('userDataCompleteInSession', this.encryptService.encryptData(JSON.stringify(dataUser)));

                    this.router.navigate(['/profile']);


                }
            );
        }else{
            this.usuarioDeportistaService.obtenerUsuarioDeportistaById(userId).subscribe(
                dataUser => {
                    this.loading = false;

                    // guardamos data user en localStorage para persistir data
                    sessionStorage.setItem('dataUser', this.encryptService.encryptData(JSON.stringify(res)));
                    sessionStorage.setItem('dataUserComplete', this.encryptService.encryptData(JSON.stringify(dataUser)));
                    sessionStorage.setItem('userDataCompleteInSession', this.encryptService.encryptData(JSON.stringify(dataUser)));

                    this.router.navigate(['/profile']);


                }
            );
        }


    }

}
