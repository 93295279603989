import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import {LocalStorageService} from '../shared/storage';

@Injectable({
    providedIn: 'root',
})
export class CarroService {

    constructor(private socket: Socket,
                private localStorageAs: LocalStorageService,
    ) {
        
    }





}
