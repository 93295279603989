<app-navbar-style-three></app-navbar-style-three>

<div class="page-title-area page-title-img-one">
    <div class="container">
        <div class="page-title-item">
            <h2>Servicios</h2>
            <ul>
                <li><a routerLink="/">Inicio</a></li>
                <li><i class='bx bx-chevron-right' ></i></li>
                <li>Servicios</li>
            </ul>
        </div>
    </div>
</div>

<section class="services-area services-area-four pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-sm-6 col-lg-4">
                <div class="services-item">
                    <a >
                        <img src="assets/img/home-one/cronometro.png" alt="Service">
                        <img class="service-shape" src="assets/img/home-one/services-shape.png" alt="Service">
                        <h3>Servicio de cronometraje electrónico
                        </h3>
                        <p>Ahora organizar un evento de calidad está a la vuelta de la esquina. Cronometra tus eventos
                            deportivos con la mejor tecnología RIFD disponible en el mercado; entrega resultados más
                            exactos y detallados a tus corredores de manera amigable e intuitiva.</p>
                    </a>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="services-item">
                    <a >
                        <img src="assets/img/home-one/services-publishing.png" alt="Service">
                        <img class="service-shape" src="assets/img/home-one/services-shape.png" alt="Service">
                        <h3>Publicación de eventos y campeonatos</h3>
                        <p>Te ofrecemos una plataforma web de muy fácil acceso en la que podrás gestionar tus propios
                            eventos y campeonatos, visualizar estadísticas, ganancias y detalle de corredores y corredoras
                            inscritos. Modifica la información cuando tu desees, te tomará menos de 5 minutos. Ten el
                            control de todo.</p>
                    </a>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="services-item">
                    <a >
                        <img src="assets/img/home-one/services3.png" alt="Service">
                        <img class="service-shape" src="assets/img/home-one/services-shape.png" alt="Service">
                        <h3>Punto de inscripción</h3>
                        <p>Ofrece a tus corredores una fácil y rápida plataforma de inscripción y pago. Nos asociamos con
                            Mercadopago y WebPay Plus para brindarte un amplio abanico de medios de pagos, ya sea
                            Débito, Crédito y Transferencias.</p>
                    </a>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="services-item">
                    <a >
                        <img src="assets/img/home-one/podium.png" alt="Service">
                        <img class="service-shape" src="assets/img/home-one/services-shape.png" alt="Service">
                        <h3>Publicación de resultados</h3>
                        <p>Publicamos y desplegamos el resultado de tus eventos y de manera fácil e intuitiva tus
                            corredores podrán visualizarlos y acceder a ellos desde cualquier dispositivo móvil o de
                            escritorio.</p>
                    </a>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="services-item">
                    <a>
                        <img src="assets/img/home-one/run.png" alt="Service">
                        <img class="service-shape" src="assets/img/home-one/services-shape.png" alt="Service">
                        <h3>Registro de asistencia y entrega de kit</h3>
                        <p>Registra la asistencia de tus corredores y entrega de kit mediante escaneo de código QR. Ahora
                            lograrás una mayor gestión y fluidez en el registro de todos los participantes.</p>
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>

<app-footer-style-two></app-footer-style-two>
