import { Component, OnInit } from '@angular/core';
import {Subject} from 'rxjs';
import {take, takeUntil} from 'rxjs/operators';
import {CorredorService} from '../../../services/corredor.service';
import {Router} from '@angular/router';
import {EncryptService} from '../../../shared/encrypt';
import {GoogleAnalytics} from '../../../shared/google-analytics';
import {ResultadoService} from '../../../services/resultado.service';


@Component({
  selector: 'app-mis-estadisticas',
  templateUrl: './mis-estadisticas.component.html',
  styleUrls: ['./mis-estadisticas.component.scss']
})
export class MisEstadisticasComponent implements OnInit {

    lineChartDataPorCategoria = [
        { data: [], label: 'Clasificación' },
    ];

    lineChartLabelsPorCategoria = [];


    lineChartDataPorGeneral = [
        { data: [], label: 'Clasificación' },
    ];

    lineChartLabelsPorGeneral = [];


    lineChartOptions = {
        responsive: true,
    };


    lineChartLegend = true;
    lineChartPlugins = [];
    lineChartType = 'line';



    public loading = false;
    public loadingGeneral = false;
    public loadingPodios = false;
    public loadingTeams = false;
    public loadingGeneralPodios = false;
    public dataUser = null;
    private compDestroy: Subject<boolean> = new Subject();
    public resultadosPorCategoria = [];
    public resultadosPorGeneral = [];
    public podiosPorCategoria = [];
    public podiosPorGeneral = [];
    public teams = [];
  constructor(
      private resultadoService: ResultadoService,
      private corredorService: CorredorService,
      private router: Router,
      private encryptService: EncryptService,
      private googleAnalytics: GoogleAnalytics

  ) { }

  ngOnInit(): void {
      this.googleAnalytics.eventEmitter('mis-estadisticas', 'exitoso', '', 'click', 0);

      this.dataUser = JSON.parse(this.encryptService.decryptData(sessionStorage.getItem('dataUserComplete')));

      this.getResultadosPorCategoria();
      this.getResultadosPorGeneral();
      this.getPodiosPorCategoria();
      this.getPodiosPorGeneral();
      this.getTeams();
  }

  getResultadosPorCategoria(){
          this.loading = true;

          const petition = this.resultadoService.obtenerTodosResultadosPorCategoriaPorCorredor(this.dataUser.id);

          petition
              .pipe(take(1), takeUntil(this.compDestroy))
              .subscribe(
                  res => {
                      this.resultadosPorCategoria = [];
                      this.resultadosPorCategoria = res;
                      const data: any [] = [];
                      this.resultadosPorCategoria.forEach((resultado) => {
                          this.lineChartLabelsPorCategoria.push(resultado.evento.nombre);
                          data.push(resultado.posicion_c);
                      });

                      this.lineChartDataPorCategoria[0].data = data;
                      this.loading = false;

                  },
                  err => {
                      this.loading = false;
                  });

  }

    getResultadosPorGeneral(){
        this.loadingGeneral = true;

        const petition = this.resultadoService.obtenerTodosResultadosPorGeneralPorCorredor(this.dataUser.id);

        petition
            .pipe(take(1), takeUntil(this.compDestroy))
            .subscribe(
                res => {
                    this.resultadosPorGeneral = [];
                    this.resultadosPorGeneral = res;
                    const data: any [] = [];
                    this.resultadosPorGeneral.forEach((resultado) => {
                        this.lineChartLabelsPorGeneral.push(resultado.evento.nombre);
                        data.push(resultado.posicion_g);
                    });

                    this.lineChartDataPorGeneral[0].data = data;
                    this.loadingGeneral = false;

                },
                err => {
                    this.loadingGeneral = false;
                });

    }

    getPodiosPorCategoria(){
        this.loadingPodios = true;

        const petition = this.resultadoService.obtenerTodosPodiosPorCategoriaPorCorredor(this.dataUser.id);

        petition
            .pipe(take(1), takeUntil(this.compDestroy))
            .subscribe(
                res => {
                    this.podiosPorCategoria = res;

                    this.loadingPodios = false;

                },
                err => {
                    this.loadingPodios = false;
                });

    }

    getTeams(){
        this.loadingTeams = true;

        const petition = this.corredorService.obtenerTodosTeamsPorCorredor(this.dataUser.id);

        petition
            .pipe(take(1), takeUntil(this.compDestroy))
            .subscribe(
                res => {
                    this.teams = res;

                    this.loadingTeams = false;

                },
                err => {
                    this.loadingTeams = false;
                });

    }

    getPodiosPorGeneral(){
        this.loadingGeneralPodios = true;

        const petition = this.resultadoService.obtenerTodosPodiosPorGeneralPorCorredor(this.dataUser.id);

        petition
            .pipe(take(1), takeUntil(this.compDestroy))
            .subscribe(
                res => {
                    this.podiosPorGeneral = res;
                    this.loadingGeneralPodios = false;

                },
                err => {
                    this.loadingGeneralPodios = false;
                });

    }

    logout() {
        window.localStorage.clear();
        window.sessionStorage.clear();

        this.router.navigate(['/']);
    }
}
