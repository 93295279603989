<div id="mapa"></div>
<div style="width: 100%;">
    <h2 style="text-align: center;">{{regionSeleccionada}}</h2>
    <div class="text-body-map" style="text-align: center;">
        <div>
            ¡Prepárate para el próximo evento en la región! Bikerace ha dejado sin aliento a más de 
        <div class="deportistas">
            <span >{{cantDeportistas}}</span>
        </div>
        <div>
            <span style="font-size: 3vw;">deportistas.</span>
        </div>
    </div>
   

</div>