import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Injectable()
export class CorredorService {

    // Observable refresh list data categorias
    flagRefresh = new BehaviorSubject<any>(null);
    observableData$ = this.flagRefresh.asObservable();

    constructor(private http: HttpClient) { }

    // actualizamos flag
    nextData(flag: boolean) {
        this.flagRefresh.next(flag);
    }


    crearCorredor(corredor: any, paymentId: string) {
        corredor.paymentId = paymentId;
        return this.http.post<any>(`${environment.api}/corredor`, corredor);
    }

    obtenerCorredor(corredorId: number) {

        return this.http.get<any>(`${environment.api}/corredor/${corredorId}`);
    }

    obtenerCorredorPorRut(rut: string) {

        return this.http.get<any>(`${environment.api}/corredor/rut/${rut}`);
    }

    obtenerMejoresCorredoresPorSexo(sexo: string) {

        return this.http.get<any>(`${environment.api}/corredor/mejores/${sexo}`);
    }

    obtenerTodosTeamsPorCorredor(usuarioId: number) {
        return this.http.get<any>(`${environment.api}/corredor/teams/usuario/${usuarioId}`);
    }

    obtenerTotalPorRegion() {
        return this.http.get<any>(`${environment.api}/corredor/region/total`);
    }

    obtenerCorredorPorEventoSimplify(eventoId: number) {

        return this.http.get<any>(`${environment.api}/corredor/simplify/evento/${eventoId}`);
    }

    obtenerInscripciones(corredorId: number) {

        return this.http.get<any>(`${environment.api}/corredor/inscripcion/${corredorId}`);
    }

    crearCorredores(corredorUno: any, corredorDos: any, paymentId: string) {
        corredorUno.paymentId = paymentId;
        corredorDos.paymentId = paymentId;

        const corredores = {
            corredores: [
                corredorUno,
                corredorDos
            ]
        };

        return this.http.post<any>(`${environment.api}/corredor/many`, corredores);
    }



}
