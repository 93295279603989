import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Injectable()
export class UsuarioDeportistaService {

  // Observable refresh list data categorias
  flagRefresh = new BehaviorSubject<any>(null);
  observableData$ = this.flagRefresh.asObservable();

  constructor(private http: HttpClient) { }

  // actualizamos flag
  nextData(flag: boolean) {
    this.flagRefresh.next(flag);
  }


      login(formLogin: any) {
        return this.http.post<any>(`${environment.api}/usuario-deportista/login`, formLogin);
      }

    registro(formRegister: any) {
        return this.http.post<any>(`${environment.api}/usuario-deportista`, formRegister);
    }

    update(id: number, formUpdate: any) {
        return this.http.patch<any>(`${environment.api}/usuario-deportista/${id}`, formUpdate);
    }

    obtenerUsuarioDeportistaById(id: number) {
        return this.http.get<any>(`${environment.api}/usuario-deportista/${id}`);
    }

    actualizarContrasena(id: number, formUpdate: any) {
        return this.http.patch<any>(`${environment.api}/usuario-deportista/password/${id}`, formUpdate);
    }

    solicitudRecuperacionContrasena(formUpdate: any) {
      return this.http.post<any>(`${environment.api}/usuario-deportista/solicitud-recuperacion-contrasena`, formUpdate);
  }

    actualizarContrasenaPorCorreo(formUpdate: any) {
      return this.http.post<any>(`${environment.api}/usuario-deportista/actualizar-contrasena`, formUpdate);
    }

  
}
