import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable, Subject, forkJoin } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { AuspiciadorService } from 'src/app/services/auspiciador.service';
import { ClienteService } from 'src/app/services/cliente.service';
import Swal from 'sweetalert2';
import { environment } from "../../../../environments/environment";
import { CorredorService } from "../../../services/corredor.service";
import { CotizacionService } from '../../../services/cotizacion.service';
import { EventoService } from '../../../services/evento.service';
import { PublicidadService } from '../../../services/publicidad.service';
import { GoogleAnalytics } from '../../../shared/google-analytics';

@Component({
    selector: 'app-home-one',
    templateUrl: './home-one.component.html',
    styleUrls: ['./home-one.component.scss']
})
export class HomeOneComponent implements OnInit {

    public loading = false;
    private compDestroy: Subject<boolean> = new Subject();
    public eventos: any[] = [];
    public afiches: any[] = [];
    public topmasculino: any[] = [];
    public topfemenino: any[] = [];
    public publicaciones: any[] = [];
    public eventosFiltro: any[] = [];
    public clientes: any[] = [];
    public auspiciadores: any[] = [];
    public corredoresPorRegion: any[] = [];


    @ViewChild('todos') todos: ElementRef;
    @ViewChild('realizados') realizados: ElementRef;
    // @ViewChild('suspendidos') suspendidos: ElementRef;
    @ViewChild('por_realizar') porRealizar: ElementRef;
    @ViewChild('ciclismo') ciclismo: ElementRef;
    @ViewChild('running') running: ElementRef;

    public filtro = 'POR_REALIZAR';

    public captchaResolved = false;
    public formCotizacion: FormGroup;

    nombre = '';
    lugar = '';

    constructor(
        private eventoService: EventoService,
        private cotizacionService: CotizacionService,
        private corredorService: CorredorService,
        private publicidadService: PublicidadService,
        private router: Router,
        private googleAnalytics: GoogleAnalytics,
        private fb: FormBuilder,
        private clienteService: ClienteService,
        private auspiciadorService: AuspiciadorService,) {

    }

    ngOnInit(): void {
        this.googleAnalytics.eventEmitter('inicio', 'exitoso', '', 'click', 0);
        this.initForm();

        this.eventoService.observableData$
            .subscribe(
                res => {
                    if (res) {
                        this.getDataDefault();
                    }
                });

        this.getDataDefault();
    }

    initForm() {
        this.formCotizacion = this.fb.group({
            nombre: ['', Validators.required],
            correoElectronico: ['', Validators.required],
            numeroTelefono: [null, Validators.required],
            numeroDeportistas: [null, Validators.required],
            lugarEvento: [null, Validators.required],
            comentarios: [null, Validators.required],
            fechaEvento: [null, Validators.required],
            token: [null, Validators.required],

        });
    }

    getDataDefault() {
        this.loading = true;
        const obtenerEventos = this.eventoService.obtenerEventosConLimiteVisibles(20);
        const obtenerAfiche = this.publicidadService.obtenerAfichePorEstado('DISPONIBLE');
        const obtenerMejoresCorredoresPorSexoFemenino = this.corredorService.obtenerMejoresCorredoresPorSexo('femenino');
        const obtenerMejoresCorredoresPorSexoMasculino = this.corredorService.obtenerMejoresCorredoresPorSexo('masculino');
        const obtenerClientesVisibles = this.clienteService.obtenerClientesVisibles();
        const obtenerAuspiciadoresVisibles = this.auspiciadorService.obtenerAuspiciadoresVisibles();
        const obtenerTotalPorRegion = this.corredorService.obtenerTotalPorRegion();

        const petitions = forkJoin([obtenerEventos, obtenerAfiche, obtenerMejoresCorredoresPorSexoFemenino, obtenerMejoresCorredoresPorSexoMasculino, obtenerClientesVisibles, obtenerAuspiciadoresVisibles, obtenerTotalPorRegion]);

        petitions
            .pipe(take(1), takeUntil(this.compDestroy))
            .subscribe(
                (res: any) => {
                    this.eventos = [];
                    res[0].forEach((element: any) => {
                        this.eventos.push(element);
                    });
                    this.afiches = [];
                    this.afiches = res[1];
                    if (this.afiches.length > 0) {
                        Swal.fire({
                            // padding: 20,
                            title: `${this.afiches[0].titulo}`,
                            // text: 'Modal with a custom image.',
                            // imageUrl: `${this.afiches[0].url_imagen}`,
                            // imageWidth: '20%',
                            // imageHeight: '40%',
                            html: `<img src="${this.afiches[0].url_imagen}">`,
                            heightAuto: false,
                            imageAlt: 'Afiche bikerace',
                            showCancelButton: true,
                            cancelButtonText: 'Cerrar',
                            confirmButtonText: `${this.afiches[0].texto_boton}`,
                            reverseButtons: true
                        }).then((result) => {
                            if (result.isConfirmed) {
                                window.open(`${this.afiches[0].url_redireccion}`, '_blank');
                            }
                        });
                    }
                    this.topfemenino = res[2];
                    this.topmasculino = res[3];

                    this.clientes = res[4];
                    this.auspiciadores = res[5];
                    this.eventosFiltro = this.eventos;
                    this.filtrarEventos('POR_REALIZAR');
                    this.corredoresPorRegion = res[6];

                    this.loading = false;
                },
                err => {
                    console.log(err);
                    this.loading = false;
                });
    }

    filtrarEventos(estado: string) {
        this.filtro = estado;


        switch (estado) {
            case 'TODAS':
                this.eventosFiltro = this.eventos.filter((evento) => evento.estado === 'POR_REALIZAR' || evento.estado === 'PROXIMAMENTE' || evento.estado === 'INSCRIPCIONES_CERRADAS' || evento.estado === 'INSCRIPCIONES_EXTERNAS').sort((a, b) => (a.fecha_evento < b.fecha_evento ? -1 : 1));
                // this.eventosFiltro = this.eventosFiltro.concat(this.eventos.filter((evento) => evento.estado !== 'POR_REALIZAR' && evento.estado !== 'PROXIMAMENTE' && evento.estado !== 'INSCRIPCIONES_CERRADAS' || evento.estado !== 'INSCRIPCIONES_EXTERNAS').sort((a, b) => (b.fecha_evento < a.fecha_evento ? -1 : 1)));
                break;
            case 'POR_REALIZAR':
                this.eventosFiltro = this.eventos.filter((evento) => evento.estado === 'POR_REALIZAR' || evento.estado === 'PROXIMAMENTE' || evento.estado === 'INSCRIPCIONES_CERRADAS' || evento.estado === 'INSCRIPCIONES_EXTERNAS');
                this.eventosFiltro = this.eventosFiltro.sort((a, b) => (a.fecha_evento < b.fecha_evento ? -1 : 1));
                break;
            case 'REALIZADO':
                this.eventosFiltro = this.eventos.filter((evento) => evento.estado === estado || evento.type === estado);
                this.eventosFiltro = this.eventosFiltro.sort((a, b) => (b.fecha_evento < a.fecha_evento ? -1 : 1));
                break;
            default:
                this.eventosFiltro = this.eventos.filter((evento) => evento.estado === estado || evento.type === estado);
                this.eventosFiltro = this.eventosFiltro.sort((a, b) => (a.fecha_evento < b.fecha_evento ? -1 : 1));
                break;

        }
    }

    // obtenerMenorPrecio(evento: any){
    //     return this.mathHelper.obtenerMenor(evento.categorias, 'valor');
    // }


    solicitarCotizacion() {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
        });

        if (!this.captchaResolved) {
            swalWithBootstrapButtons.fire(
                'Verificación.',
                'Te falto pasar por el proceso de verificación',
                'error'
            );

            return;
        }

        if (this.formCotizacion.invalid) {
            for (const control of Object.keys(this.formCotizacion.controls)) {
                this.formCotizacion.controls[control].markAsTouched();
            }
            return;
        }



        this.loading = true;
        const petition: Observable<any> = this.cotizacionService.solicitarCotizacion(this.formCotizacion.value);

        petition
            .pipe(take(1), takeUntil(this.compDestroy))
            .subscribe(
                res => {
                    if (res === true) {

                        this.formCotizacion.reset();

                        swalWithBootstrapButtons.fire(
                            'Solicitud recibida',
                            'Te contáctaremos lo antes posible.',
                            'success'
                        );

                    } else {
                        swalWithBootstrapButtons.fire(
                            'No se pudo procesar tu solicitud.',
                            'Verifica la validez de los datos ingresados.',
                            'error'
                        );
                    }

                    this.loading = false;

                },
                err => {
                    if (err) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: JSON.parse(err.error).message,
                        })

                    }
                    this.loading = false;
                });




    }

    checkCaptcha(captchaResponse: string) {
        this.captchaResolved = (captchaResponse && captchaResponse.length > 0);
    }

    camelCaseToWords(str: string) {
        return str
            .trim()
            .toLowerCase()
            .replace(/^[a-z]/g, char => ` ${char.toUpperCase()}`)
            .replace(/[A-Z]|[0-9]+/g, ' $&')
            .replace(/(?:\s+)/, char => '');
    };

    getEnvironmentAdSense() {
        return environment.adSenseClient;
    }

    clickAppStore() {
        Swal.fire({
            title: 'Pronto',
            text: "Estamos trabajando para tener disponible la aplicación en App Store",
            icon: 'info',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ok',
        });
    }

    buscar() {
        let query = `${this.nombre} ${this.lugar}`;
        if (query[0] === ' ') {
            query = query.slice(1);
        }

        if (query[query.length - 1] === ' ') {
            query = query.substring(0, query.length - 1);

        }

        this.router.navigate(['/eventos'], { queryParams: { query: `${query}` } });

    }
}
