import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-payment-app',
    templateUrl: './payment-app.component.html',
    styleUrls: ['./payment-app.component.scss']
})
export class PaymentAppComponent implements OnInit {

    loading = true;


    private compDestroy: Subject<boolean> = new Subject();

    constructor(


    ) {

    }

    ngOnInit(): void {

    }
}
