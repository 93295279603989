<app-navbar-style-three></app-navbar-style-three>

<div class="services-details-area pt-100 pb-70">
    <div class="container" *ngIf="!loading">
        <div class="row">
            <div class="col-lg-3">
                <div class="services-details-item">
                    <div class="services-details-order">
                        <h3>Pago</h3>
                        <span>#{{transaccion.id}}</span>
                        <img *ngIf="transaccion.platform === 'MERCADO PAGO'"
                            src="https://firebasestorage.googleapis.com/v0/b/bikerace-qa.appspot.com/o/mercadopago.png?alt=media&token=875507df-fadd-410b-b819-8c61cee2e50e" alt="Service" style="margin: 0 15px 30px 15px; width: 30%;">
                        <img *ngIf="transaccion.platform === 'WEB PAY'"
                            src="https://firebasestorage.googleapis.com/v0/b/bikerace-qa.appspot.com/o/webpay-plus.png?alt=media&token=04424dbe-5609-4be2-806e-1866008ea37c" alt="Service" style="margin: 0 15px 30px 15px; width: 30%;">
                        <br>
                    </div>
                </div>


            </div>

            <div class="col-lg-9">
                <div class="services-details-item">
                    <div class="services-details-fresh">
                        <h2>Comprobante de pago #{{transaccion.id}}</h2>
                        <ul>
                            <li><strong>Fecha pago: </strong>{{transaccion.createdAt | date : 'dd-MM-yyyy' : 'UTC'}}</li>
                            <li><strong>Estado: </strong>{{transaccion.estado}}</li>
                            <li><strong>Usuario pagador: </strong>{{transaccion.corredores[0].usuario.firstName}} {{transaccion.corredores[0].usuario.lastName}}</li>
                            <li><strong>Monto: </strong>${{transaccion.monto}}</li>
                            <li><strong>Plataforma de pago: </strong>{{transaccion.platform}}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<app-footer-style-two></app-footer-style-two>
<app-preloader *ngIf="loading" ></app-preloader>
