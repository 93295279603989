import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Injectable()
export class ResultadoService {

  // Observable refresh list data categorias
  flagRefresh = new BehaviorSubject<any>(null);
  observableData$ = this.flagRefresh.asObservable();

  constructor(private http: HttpClient) { }

  // actualizamos flag
  nextData(flag: boolean) {
    this.flagRefresh.next(flag);
  }

  obtenerResultados() {
    return this.http.get<any>(`${environment.api}/resultado`);
  }

  obtenerResultadosPorEvento(eventoId: number) {
    return this.http.get<any>(`${environment.api}/resultado/evento/${eventoId}`);
  }

    obtenerTodosResultadosPorGeneralPorCorredor(usuarioId: number) {
    return this.http.get<any>(`${environment.api}/resultado/general/usuario/${usuarioId}`);
  }

    obtenerTodosResultadosPorCategoriaPorCorredor(usuarioId: number) {
    return this.http.get<any>(`${environment.api}/resultado/categoria/usuario/${usuarioId}`);
  }


    obtenerTodosPodiosPorGeneralPorCorredor(usuarioId: number) {
        return this.http.get<any>(`${environment.api}/resultado/podios/general/usuario/${usuarioId}`);
    }

    obtenerTodosPodiosPorCategoriaPorCorredor(usuarioId: number) {
        return this.http.get<any>(`${environment.api}/resultado/podios/categoria/usuario/${usuarioId}`);
    }



}
