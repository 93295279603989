<!-- <app-navbar-style-three></app-navbar-style-three> -->

<!--ESPERANDO PAGO-->
<ng-container *ngIf="loading">
    <div class="page-title-area page-title-img-one">
        <div class="container">
            <div class="page-title-item">
                <!-- <h2>Esperando pago de la inscripción</h2> -->

                <!-- <ul>
                    <li><a routerLink="/">Inicio</a></li>
                    <li><i class='bx bx-chevron-right' ></i></li>
                    <li>Pago</li>
                </ul> -->
            </div>
        </div>
    </div>

    <section class="checkout-area ptb-100" >
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="checkout-item" style="border: none; text-align: center;">
                        
                        <h5>
                            Estamos procesando tu compra...
                        </h5>
                        <br>
                        <br>
                        <img src="https://s3.amazonaws.com/static.khipu.com/buttons/2021/200x75-purple.png" alt="">

                    </div>
                </div>
            </div>
        </div>
    </section>
</ng-container>

<!--PAGO APROBADO-->
<!-- <ng-container *ngIf="!loading && pagoConfirmado === true">
    <div class="page-title-area page-title-img-one">
        <div class="container">
            <div class="page-title-item">
                <h2>Se ha aceptado el pago de manera exitosa</h2>

                <ul>
                    <li><a routerLink="/">Inicio</a></li>
                    <li><i class='bx bx-chevron-right' ></i></li>
                    <li>Pago</li>
                </ul>
            </div>
        </div>
    </div>

    <section class="checkout-area ptb-100" >
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="checkout-item" >
                        <h2 >Detalle de pago</h2>

                        <div class="checkout-two">
                            <h3>Pago realizado con éxito</h3>

                            <p>Monto abonado: <strong>${{response.amount}}</strong></p>
                            <p>Te hemos enviado un correo con la información del evento, si no lo encuentras búscalos en SPAM o promociones :)</p>

                            <img *ngIf="paymentType === 'KHIPU'"  src="https://s3.amazonaws.com/static.khipu.com/buttons/2021/200x75-purple.png" alt="">

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</ng-container> -->

<!--PAGO FALLIDO-->
<!-- <ng-container *ngIf="!loading && pagoFallido === true">
    <div class="page-title-area page-title-img-one">
        <div class="container">
            <div class="page-title-item">
                <h2 *ngIf="!loading && pagoFallido === true">El pago no se pudo realizar</h2>

                <ul>
                    <li><a routerLink="/">Inicio</a></li>
                    <li><i class='bx bx-chevron-right' ></i></li>
                    <li>Pago</li>
                </ul>
            </div>
        </div>
    </div>

    <section class="checkout-area ptb-100" >
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="checkout-item" >
                        <h2>Detalle de pago</h2>

                        <div class="checkout-two">
                            <h3>El pago no se pudo realizar</h3>

                            <p>Monto abonado: <strong>${{response.amount}}</strong></p>
                            <p>El pago no se pudo completar, por lo que no se te descontó dinero de tu cuenta, si tienes preguntas escríbenos a <a style="display: initial !important" href="mailto:hola@bikerace.cl">hola@bikerace.cl</a></p>

                            <img *ngIf="paymentType === 'MERCADO_PAGO'" src="assets/img/mercadopago.jpg" alt="">
                            <img *ngIf="paymentType === 'WEBPAY'"  src="assets/img/webpay-plus.png" alt="">
                            <img *ngIf="paymentType === 'KHIPU'"  src="https://s3.amazonaws.com/static.khipu.com/buttons/2021/200x75-purple.png" alt="">

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</ng-container> -->

<!-- <app-footer-style-two></app-footer-style-two> -->
