<app-navbar-style-three></app-navbar-style-three>

<div class="services-details-area detail-inscription pt-100 pb-70">
    <div class="container" >
        <div class="row">
            <div class="col-lg-3" *ngIf="!loading">
                <div class="services-details-item">
                    <div class="services-details-order" *ngIf="corredor.transaccion.estado === 'APROBADO'" style="background-color: green">
                        <h3>Inscripción</h3>
                        <span>#{{corredor.id}}</span>
                        <ngx-qrcode
                        [elementType]="elementType"
                        [value]="url"
                        cssClass="aclass"
                        [errorCorrectionLevel]="errorCorrectionLevel">
                        </ngx-qrcode>
                        <br>
                    </div>
                    <div class="services-details-order" *ngIf="corredor.transaccion.estado === 'PENDIENTE'" style="background-color: red">
                        <h3>Inscripción</h3>
                        <span>#{{corredor.id}}</span>
                        <ngx-qrcode
                            [elementType]="elementType"
                            [value]="url"
                            cssClass="aclass"
                            [errorCorrectionLevel]="errorCorrectionLevel">
                        </ngx-qrcode>
                        <br>
                    </div>
                    <div class="services-details-order" *ngIf="corredor.transaccion.estado === 'REVISION_TRANSFERENCIA'" style="background-color: orange">
                        <h3>Inscripción</h3>
                        <span>#{{corredor.id}}</span>
                        <ngx-qrcode
                            [elementType]="elementType"
                            [value]="url"
                            cssClass="aclass"
                            [errorCorrectionLevel]="errorCorrectionLevel">
                        </ngx-qrcode>
                        <br>
                    </div>

                    <div class="services-details-order" *ngIf="corredor.transaccion.estado === 'EXPIRADA'" style="background-color: red">
                        <h3>Inscripción</h3>
                        <span>#{{corredor.id}}</span>
                        <ngx-qrcode
                            [elementType]="elementType"
                            [value]="url"
                            cssClass="aclass"
                            [errorCorrectionLevel]="errorCorrectionLevel">
                        </ngx-qrcode>
                        <br>
                    </div>
                </div>

                <div class="services-details-item" *ngIf="corredor.transaccion.estado === 'APROBADO'" (click)="verTicket()" style="cursor: pointer;">
                        <div class="services-details-order" style="background-color: green">
                            <h3>Mi ticket</h3>

                            <img src="assets/img/boleto.png" style="width: 17%;margin-bottom: 26px;">
                            <br>
                        </div>


                </div>

                <div class="services-details-item">
                    <div class="">
                        <img [src]="corredor.evento.url_foto" alt="Service" style="margin-bottom: 16px;">

                        <br>
                    </div>
                </div>
            </div>

            <div class="col-lg-9">

                <div class="content" *ngIf="!loading">
                    <div class="container">
            
                        <div class="row">
                            <div class="col-lg-8 offset-lg-2">
                                <div class="invoice-content">
                                    <div class="invoice-item">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="invoice-logo">
                                                    <img src="assets/img/logobikerace.png" alt="logo">
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <p class="invoice-details">
                                                    <strong>Inscripción:</strong> #{{corredor.id}} <br>
                                                    <strong>Fecha:</strong> {{corredor.transaccion.createdAt| date : 'dd-MM-yyyy HH:mm:ss'}}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <!-- Invoice Item -->
                                    <div class="invoice-item">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="invoice-info">
                                                    <strong class="customer-text">Deportista</strong>
                                                    <p class="invoice-details invoice-details-two">
                                                        {{corredor.nombre}} {{corredor.apellido}}<br>
                                                        DNI: {{corredor.rut}},<br>
                                                        Correo electrónico: {{corredor.email}} <br>
                                                    </p>
                                                </div>
                                            </div>
                                            <!-- <div class="col-md-6">
                                                <div class="invoice-info invoice-info2">
                                                    <strong class="customer-text">Invoice To</strong>
                                                    <p class="invoice-details">
                                                        Walter Roberson <br>
                                                        299 Star Trek Drive, Panama City, <br>
                                                        Florida, 32405, USA <br>
                                                    </p>
                                                </div>
                                            </div> -->
                                        </div>
                                    </div>
                                    <!-- /Invoice Item -->
                                    
                                    <!-- Invoice Item -->
                                    <div class="invoice-item" *ngIf="corredor.transaccion.estado === 'APROBADO'">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="invoice-info">
                                                    <strong class="customer-text">Método de pago</strong>
                                                    <p class="invoice-details invoice-details-two">
                                                        {{corredor.transaccion.method_payment}} <br>
                                                        <!-- XXXXXXXXXXXX-2541 <br> -->
                                                        {{corredor.transaccion.platform}}<br>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- /Invoice Item -->
                                    
                                    <!-- Invoice Item -->
                                    <div class="invoice-item invoice-table-wrap" *ngIf="corredor.transaccion.estado === 'APROBADO'">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="table-responsive">
                                                    <table class="invoice-table table table-bordered">
                                                        <thead>
                                                            <tr>
                                                                <th>Inscripción</th>
                                                                <th class="text-center">Cantidad</th>
                                                                <th class="text-center">Valor</th>
                                                                <th class="text-right">Total</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    <ul>
                                                                        <li><strong>Nombre evento: </strong>{{corredor.evento.nombre }}</li>
                                                                        <li><strong>Fecha evento: </strong>{{corredor.evento.fecha_evento | date : 'dd-MM-yyyy' : 'UTC'}}</li>
                                                                        <li><strong>Hora citación: </strong>{{corredor.evento.hora_citacion}}</li>
                                                                        <li><strong>Hora largada: </strong>{{corredor.evento.hora_largada}}</li>
                                                                    </ul>
                                                                    <hr>
                                                                    <ul>
                                                                        <li><strong>Deportista: </strong>{{corredor.nombre}} {{corredor.apellido}}</li>
                                                                        <li><strong>DNI: </strong>{{corredor.rut}}</li>
                                                                        <li><strong>Categoria: </strong>{{corredor.categoria.nombre}} {{corredor.categoria.modo}} {{corredor.categoria.sexo}}</li>
                                                                        <li><strong>Desafío: </strong>{{corredor.categoria.desafioEntity.nombre}}</li>
                                        
                                                                    </ul>
                                                                </td>
                                                                <td class="text-center">1</td>
                                                                <td class="text-center">${{corredor.transaccion.monto_inscripcion}}</td>
                                                                <td class="text-right">${{corredor.transaccion.monto_inscripcion}}</td>
                                                            </tr>
                                                            <!-- <tr>
                                                                <td>Video Conference</td>
                                                                <td class="text-center">1</td>
                                                                <td class="text-center">$0</td>
                                                                <td class="text-right">$250</td>
                                                            </tr> -->
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <div class="col-md-12 col-xl-12">
                                                <div class="table-responsive">
                                                    <table class="invoice-table-two table">
                                                        <tbody>
                                                        <tr>
                                                            <th>Subtotal:</th>
                                                            <td><span>${{round(corredor.transaccion.monto_inscripcion)}}</span></td>
                                                        </tr>
                                                       
                                                        <tr>
                                                            <th>Costo por transacción:</th>
                                                            <td><span>+${{round(corredor.transaccion.monto - corredor.transaccion.monto_inscripcion)}}</span></td>
                                                        </tr>
                                                        <tr >
                                                            <th>Total Pago:</th>
                                                            <td><span>${{round(corredor.transaccion.monto_inscripcion + (corredor.transaccion.monto - corredor.transaccion.monto_inscripcion))}}</span></td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- /Invoice Item -->
                                
            
                                    <div class="other-info" *ngIf="corredor.transaccion.estado !== 'APROBADO'">
                                        <h4>Otra información</h4>
                                        <p class="text-muted mb-0">LA INSCRIPCIÓN NO ESTÁ APROBADA</p>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
            
                    </div>
            
                </div>      
            </div>
        </div>
    </div>
</div>


<app-footer-style-two></app-footer-style-two>
<app-preloader *ngIf="loading" ></app-preloader>
