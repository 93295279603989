import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { RutValidator } from 'ng9-rut';
import { firstValueFrom, forkJoin, Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { CalificacionService } from 'src/app/services/calificacion.service';
import Swal from 'sweetalert2';
import { EventoService } from '../../../services/evento.service';

@Component({
    selector: 'app-calificar',
    templateUrl: './calificar.component.html',
    styleUrls: ['./calificar.component.scss']
})
export class CalificarComponent implements OnInit {

    public loading = false;
    private compDestroy: Subject<boolean> = new Subject();
    public formBusqueda: FormGroup;
    public formCalificacion: FormGroup;
    public eventos: any[] = [];
    public categorias: any[] = [];
    public solicitud;
    public corredor;
    public extranjero = false;


    constructor(private activatedroute: ActivatedRoute,
        private eventoService: EventoService,
        private calificacionService: CalificacionService,
        private fb: FormBuilder,
        private router: Router,
        private rutValidator: RutValidator,

    ) {

    }

    ngOnInit(): void {
        this.initFormBusqueda();
        this.initFormSolicitud();
    }


    initFormBusqueda() {
        this.formBusqueda = this.fb.group({
            rut: ['', [Validators.required, this.rutValidator, Validators.minLength(5)]],
        });
    }

    initFormSolicitud() {
        this.formCalificacion = this.fb.group({
            eventoId: [null, Validators.required],
            comentario: [null, Validators.required],
            calificacion: [null, Validators.required],
        });
    }

    buscarRut() {
        this.eventos = [];
        this.formCalificacion.reset();


        if (!this.formBusqueda.valid) {
            Swal.fire(
                'Campos incompletos',
                `El campo RUT debe ser válido.`,
                'error'
            );

            return;
        }

        this.loading = true;
        const obtenerEventosPorCorredorRutYEstado: Observable<any> = this.eventoService.obtenerEventosPorCorredorRutYEstado(this.formBusqueda.get('rut').value, 'REALIZADO');

        const petitions = forkJoin([obtenerEventosPorCorredorRutYEstado]);

        petitions.pipe(take(1), takeUntil(this.compDestroy))
            .subscribe(
                res => {
                    this.eventos = res[0];
                    this.loading = false;

                },
                err => {
                    if (err) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: JSON.parse(err.error).message,
                        })

                    }
                    this.loading = false;
                });
    }


    enviarSolicitud() {

        if (!this.formCalificacion.valid) {
            Swal.fire(
                'Campos incompletos',
                `Todos los campos del formulario deben ser completados`,
                'error'
            );

            return;
        }

        const petitionData = {
            corredorId: this.eventos.find((evento) => evento.id === Number(this.formCalificacion.get('eventoId').value)).corredor[0].id,
            comentario: this.formCalificacion.get('comentario').value,
            calificacion: this.formCalificacion.get('calificacion').value,
        };


        this.loading = true;
        const petition: Observable<any> = this.calificacionService.crearSolicitudCodigo(petitionData);

        petition
            .pipe(take(1), takeUntil(this.compDestroy))
            .subscribe(
                res => {
                    this.solicitud = res;
                    this.loading = false;
                    Swal.fire({
                        title: 'Código de confirmación',
                        text: 'Te hemos enviado un código dinámico de confirmación a tu correo electrónico, ingresalo acá para confirmar la calificación.',
                        input: 'text',
                        inputAttributes: {
                            autocapitalize: 'off'
                        },
                        showCancelButton: true,
                        confirmButtonText: 'Confirmar',
                        cancelButtonText: 'Cancelar',
                        showLoaderOnConfirm: true,
                        preConfirm: (codigo) => {

                            return firstValueFrom(this.calificacionService.validacionSolicitudCodigo(this.solicitud.id, { 'codigo': codigo })).then((data) => {
                                console.log(data);
                            }).catch((error) => {
                                console.log(error);
                                Swal.showValidationMessage(
                                    JSON.parse(error.error).message
                                );
                            })
                        },
                        allowOutsideClick: () => !Swal.isLoading()
                    }).then((result) => {
                        if (result.isConfirmed) {
                            Swal.fire(
                                'Calificación publicada!',
                                'Has calificado una evento con éxito, tu feedback es muy valioso para nosotros y ayuda a mejorar la calidad de los eventos.',
                                'success'
                            );

                            this.router.navigate(['/']);


                        }
                    });
                },
                err => {
                    if (err) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: JSON.parse(err.error).message,
                        })

                    }
                    this.loading = false;
                });
    }

    changeExtranjero(esExtranjero: boolean) {
        this.extranjero = esExtranjero;
        this.changeTypeDocument();
    }

    changeTypeDocument() {

        switch (this.extranjero) {
            case false:
                const validator: any[] = [Validators.required, this.rutValidator, Validators.minLength(5)];
                this.formBusqueda.get('rut').setValidators(validator);
                break;
            case true:
                this.formBusqueda.get('rut').setValidators([Validators.required]);

                break;
        }
    }

    onRatingChanged(rating: number): void {
        this.formCalificacion.get('calificacion').setValue(rating);
      }
}
