<app-navbar-style-three></app-navbar-style-three>

<div class="page-title-area page-title-img-one">
    <div class="container">
        <div class="page-title-item">
            <h2>Calificaciones</h2>
            <ul>
                <li><a routerLink="/">Inicio</a></li>
                <li><i class='bx bx-chevron-right' ></i></li>
                <li>Calificaciones</li>
            </ul>
        </div>
    </div>
</div>

<div class="book-table-area ptb-100"  >
    <div  class="container">
        <div class="book-table-wrap" *ngIf="calificaciones.length > 0">
            <div class="section-title">
                <h2>Calificaciones</h2>
            </div>

                <div class="container">
                    <div class="row resumen">
                        <div class="col-lg-6 item" >
                            <app-estrellas-calificacion [edit]="false" [small]="true" [initValue]="5"></app-estrellas-calificacion><br>
                            {{calificaciones.length}} Calificaciones
                        </div>
                        <br>
                        <div class="col-lg-6 item">
                            <app-estrellas-calificacion [edit]="false" [small]="true" [initValue]="5"></app-estrellas-calificacion> ({{contarCantidadCalificaciones(5)}})<br>
                            <app-estrellas-calificacion [edit]="false" [small]="true" [initValue]="4"></app-estrellas-calificacion> ({{contarCantidadCalificaciones(4)}})<br>
                            <app-estrellas-calificacion [edit]="false" [small]="true" [initValue]="3"></app-estrellas-calificacion> ({{contarCantidadCalificaciones(3)}})<br>
                            <app-estrellas-calificacion [edit]="false" [small]="true" [initValue]="2"></app-estrellas-calificacion> ({{contarCantidadCalificaciones(2)}})<br>
                            <app-estrellas-calificacion [edit]="false" [small]="true" [initValue]="1"></app-estrellas-calificacion> ({{contarCantidadCalificaciones(1)}})<br>
                        </div>

                    </div>
                    <hr>
                    <div class="row">
                        <div class="calificacion-item" *ngFor="let calificacion of calificaciones">
                            <span class="image">{{obtenerIniciales(calificacion)}}</span>
                            <span class="body">
                                <span class="title"><b>{{calificacion.corredor.nombre}}:</b> {{calificacion.comentario}}</span>
                                <br>
                                <span class="calificacion">
                                    <app-estrellas-calificacion [edit]="false" [small]="true" [initValue]="calificacion.calificacion"></app-estrellas-calificacion>

                                </span>
                            </span>
                        </div>

                        
                    </div>
            </div>

        </div>
    </div>

</div>

<app-footer-style-two></app-footer-style-two>

<app-preloader *ngIf="loading" ></app-preloader>
