import { Component, OnInit } from '@angular/core';
import {Subject} from 'rxjs';
import {take, takeUntil} from 'rxjs/operators';
import {CorredorService} from '../../../services/corredor.service';
import {Router} from '@angular/router';
import {EncryptService} from '../../../shared/encrypt';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {UsuarioDeportistaService} from '../../../services/usuario-deportista.service';
import Swal from 'sweetalert2';
import {UsuarioOrganizadorService} from '../../../services/usuario-organizador.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

    public formChangePassword: FormGroup ;
    public loading = false;
    public dataUser = null;
    private compDestroy: Subject<boolean> = new Subject();
    public showPassword = false;

  constructor(private usuarioDeportistaService: UsuarioDeportistaService,
              private usuarioOrganizadorService: UsuarioOrganizadorService,
              private router: Router,
              private encryptService: EncryptService,
              private fb: FormBuilder,
  ) { }

  ngOnInit(): void {
      this.dataUser = JSON.parse(this.encryptService.decryptData(sessionStorage.getItem('dataUserComplete')));
      this.initForm();

  }

    initForm() {
        this.formChangePassword = this.fb.group({
            passwordOld: ['', Validators.required],
            password: ['', Validators.required]
        });
    }

    logout() {
        window.localStorage.clear();
        window.sessionStorage.clear();

        this.router.navigate(['/']);
    }

    changePassword(){
        this.loading = true;

        let petition = null;

        if (this.dataUser.role === 'Organizador'){
            petition = this.usuarioOrganizadorService.actualizarContrasena(this.dataUser.id, this.formChangePassword.value);
        }else{
            petition = this.usuarioDeportistaService.actualizarContrasena(this.dataUser.id, this.formChangePassword.value);
        }

        petition
            .pipe(take(1), takeUntil(this.compDestroy))
            .subscribe(
                res => {
                    this.loading = false;

                    const swalWithBootstrapButtons = Swal.mixin({
                        customClass: {
                            confirmButton: 'btn btn-success',
                            cancelButton: 'btn btn-danger'
                        },
                        buttonsStyling: false
                    });

                    swalWithBootstrapButtons.fire({
                        title: 'Muy bien!',
                        text: 'Has cambiado tu contraseña exitosamente, debes iniciar sesión nuevamente',
                        showCancelButton: false,
                        confirmButtonText: 'Iniciar sesión',
                        reverseButtons: true,
                        icon: 'success'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            this.logout();
                        } else if (
                            /* Read more about handling dismissals below */
                            result.dismiss === Swal.DismissReason.cancel
                        ) {
                            this.logout();
                        }else if (
                            result.dismiss === Swal.DismissReason.backdrop
                        ){
                            this.logout();

                        }
                    });

                },
                err => {
                    if (err) {
                        Swal.fire({
                          icon: 'error',
                          title: 'Oops...',
                          text: JSON.parse(err.error).message,
                        })
            
                      }
                    this.loading = false;
                });
    }

    changeShowPass(){
      this.showPassword = !this.showPassword;
    }
}
