import { Component, OnInit } from '@angular/core';
import {forkJoin, Subject} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {take, takeUntil} from 'rxjs/operators';
import {NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels} from '@techiediaries/ngx-qrcode';
import {environment} from '../../../../environments/environment';
import {TransaccionService} from '../../../services/transaccion.service';

@Component({
  selector: 'app-transaction-details',
  templateUrl: './transaction-details.component.html',
  styleUrls: ['./transaction-details.component.scss']
})
export class TransactionDetailsComponent implements OnInit {
    public id = 0;
    public transaccion: any;
    private compDestroy: Subject<boolean> = new Subject();
    public loading = false;
    url = environment.urlBikeraceWeb + 'detalle-inscripcion?id=';
    elementType = NgxQrcodeElementTypes.URL;
    errorCorrectionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;

    constructor(
        private activatedroute: ActivatedRoute,
        private transaccionService: TransaccionService,
    ) {
        this.activatedroute.queryParams.subscribe(params => {
            this.id = Number(params.id);
        });
    }

  ngOnInit(): void {
      this.transaccionService.observableData$
          .subscribe(
              res => {
                  if (res) {
                      this.getDataDefault();
                  }
              });

      this.getDataDefault();
  }

    getDataDefault() {
        this.loading = true;
        const obtenerTransaccion = this.transaccionService.obtenerTransaccionesPorId(this.id);

        const petitions = forkJoin([obtenerTransaccion]);

        petitions
            .pipe(take(1), takeUntil(this.compDestroy))
            .subscribe(
                res => {
                    this.transaccion = res[0];
                    this.loading = false;
                },
                err => {
                    console.log(err);
                    this.loading = false;
                });
    }
}
