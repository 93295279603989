<app-navbar-style-three></app-navbar-style-three>

<app-banner-custom *ngIf="!loadingInfoInicial" [urlBanner]="evento.url_banner" [eventName]="evento.nombre" routerOne="/"
    navigationOne="Inicio" [navigationTwo]="evento.nombre"></app-banner-custom>

<div class="book-table-area ptb-100" *ngIf="!loadingInfoInicial && evento.estado === 'POR_REALIZAR'">
    <div class="container">
        <div class="row">
            <div class="col-lg-9 col-md-12">
                <div class="container">
                    <div class="book-table-wrap">
                        <div class="section-title">
                            <h2><span *ngIf="categoria !== null && categoria.modo === 'duplas'">Persona 1 -
                                </span>Inscripción a evento: {{evento.nombre}}</h2>
                            <span *ngIf="evento.campeonato !== null" style="color:red">NOTA: Este evento pertenece a un
                                campeonato. Asegúrate de correr en la misma categoría durante todo el campeonato para
                                que tu posición en el ranking esté correcto.</span>
                        </div>

                        <ng-container [formGroup]="formInscripcion">
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label>Nombres: <span style="color:red">(requerido)</span></label>
                                        <input type="text" formControlName="nombre" class="form-control"
                                            placeholder="Nombre">
                                        <span
                                            *ngIf="formInscripcion.controls['nombre'].invalid && (formInscripcion.controls['nombre'].dirty || formInscripcion.controls['nombre'].touched)"
                                            style="color:red"><b>El campo nombre es obligatorio.</b></span>

                                    </div>
                                </div>

                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label>Apellidos: <span style="color:red">(requerido)</span></label>
                                        <input type="text" formControlName="apellido" class="form-control"
                                            placeholder="Apellidos">
                                        <span
                                            *ngIf="formInscripcion.controls['apellido'].invalid && (formInscripcion.controls['apellido'].dirty || formInscripcion.controls['apellido'].touched)"
                                            style="color:red"><b>El campo apellidos es obligatorio.</b></span>

                                    </div>
                                </div>


                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label>Correo electrónico: <span style="color:red">(requerido)</span></label>
                                        <input type="email" formControlName="email" class="form-control"
                                            placeholder="Correo electrónico">
                                        <label>*Nota: Debes ingresar un correo electrónico válido ya que te llegará el
                                            comprobante de inscripción</label><br>
                                        <span
                                            *ngIf="formInscripcion.controls['email'].invalid && (formInscripcion.controls['email'].dirty || formInscripcion.controls['email'].touched)"
                                            style="color:red"><b>El campo correo electrónico es obligatorio.</b></span>


                                    </div>
                                </div>

                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label>Soy Federado: <span style="color:red">(requerido)</span></label>
                                        <br>
                                        <label>
                                            <input type="radio" value="Federado" formControlName="federado"
                                                (change)="filtrarCategorias()">&nbsp;
                                            <span>Federado</span>
                                        </label>
                                        <br>
                                        <label>
                                            <input type="radio" value="No federado" formControlName="federado"
                                                (change)="filtrarCategorias()">&nbsp;
                                            <span>No Federado</span>
                                        </label>
                                        <label>*Nota: Al seleccionar federado deberás ingresar el campo ID
                                            UCI.</label><br>
                                    </div>
                                </div>

                                <div class="col-lg-6" *ngIf="mostrarIdUCIPersona1()">
                                    <div class="form-group">
                                        <label>ID UCI: <span style="color:red">(requerido)</span></label>
                                        <input type="number" maxlength="11" formControlName="id_uci"
                                            class="form-control" placeholder="ID UCI">
                                    </div>
                                </div>

                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label>Fecha de nacimiento: <span style="color:red">(requerido)</span></label>
                                        <input type="date" formControlName="fecha_nacimiento" class="form-control"
                                            placeholder="Fecha de nacimiento" (change)="filtrarCategorias()">
                                        <span
                                            *ngIf="formInscripcion.controls['fecha_nacimiento'].invalid && (formInscripcion.controls['fecha_nacimiento'].dirty || formInscripcion.controls['fecha_nacimiento'].touched)"
                                            style="color:red"><b>El campo fecha de nacimiento es obligatorio.</b></span>

                                    </div>
                                </div>

                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label *ngIf="getTipoDocumento1() === 'RUT'">RUT: <span
                                                style="color:red">(requerido)</span></label>
                                        <input *ngIf="getTipoDocumento1() === 'RUT'" type="text" formatRut
                                            formControlName="rut" class="form-control" placeholder="RUT">


                                        <label *ngIf="getTipoDocumento1() === 'passport'">Pasaporte: <span
                                                style="color:red">(requerido)</span></label>
                                        <input *ngIf="getTipoDocumento1() === 'passport'" type="text"
                                            formControlName="rut" class="form-control" placeholder="DNI">
                                        <input type="checkbox" [checked]="extranjero1"
                                            (change)="changeExtranjero1(!extranjero1)"><label
                                            (click)="changeExtranjero1(!extranjero1)">&nbsp;&nbsp;Soy extranjero</label>
                                        <br>
                                        <span
                                            *ngIf="formInscripcion.controls['rut'].invalid && (formInscripcion.controls['rut'].dirty || formInscripcion.controls['rut'].touched)"
                                            style="color:red">
                                            <b *ngIf="getTipoDocumento1() === 'RUT'">Debes ingresar un rut válido.</b>
                                            <b *ngIf="getTipoDocumento1() === 'passport'">Debes ingresar un pasaporte
                                                válido.</b>
                                        </span>
                                    </div>




                                </div>


                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label>Sexo: <span style="color:red">(requerido)</span></label>
                                        <select formControlName="sexo" class="form-control"
                                            (change)="filtrarCategorias()">
                                            <option value="masculino">Masculino</option>
                                            <option value="femenino">Femenino</option>
                                        </select>
                                        <span
                                            *ngIf="formInscripcion.controls['sexo'].invalid && (formInscripcion.controls['sexo'].dirty || formInscripcion.controls['sexo'].touched)"
                                            style="color:red"><b>El campo sexo es obligatorio.</b></span>

                                    </div>
                                </div>


                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label>Desafío: <span style="color:red">(requerido)</span></label>
                                        <select formControlName="desafio" class="form-control"
                                            (change)="filtrarCategorias()">
                                            <option value="0">Selecciona desafío</option>

                                            <ng-container *ngFor="let desafio of desafios">
                                                <option *ngIf="!obtenerDesafioPorDesafioSinCupo(desafio)"
                                                    [value]="desafio.id">{{desafio.nombre}}
                                                </option>

                                                <option *ngIf="obtenerDesafioPorDesafioSinCupo(desafio)" disabled
                                                    [value]="desafio.id">SIN CUPO - {{desafio.nombre}}
                                                </option>
                                            </ng-container>
                                        </select>

                                    </div>
                                </div>

                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label>Categoria: <span style="color:red">(requerido)</span></label>
                                        <select formControlName="categoria" class="form-control"
                                            (change)="changeCategoria()">
                                            <ng-container *ngFor="let categoria of obtenerCategoriasFiltradas()">
                                                <option
                                                    *ngIf="obtenerUltimaConfiguracionCat(categoria).estado === 'NO DISPONIBLE' && !obtenerDesafioSinCupo(categoria)"
                                                    style="color:red !important;" disabled [value]="categoria.id">(NO
                                                    DISPONIBLE) - {{categoria.nombre}} -
                                                    {{categoria.desafioEntity.nombre}} - {{categoria.modo}}<span
                                                        *ngIf="mostrarIdUCIPersona1()"> - {{categoria.federada ===
                                                        'ACTIVADA' ? 'Federada':'No federada'}}</span>
                                                </option>

                                                <option
                                                    *ngIf="obtenerUltimaConfiguracionCat(categoria).estado === 'SIN CUPOS' && !obtenerDesafioSinCupo(categoria)"
                                                    style="color:red !important;" disabled [value]="categoria.id">(SIN
                                                    CUPOS) - {{categoria.nombre}} - {{categoria.desafioEntity.nombre}} -
                                                    {{categoria.modo}}<span *ngIf="mostrarIdUCIPersona1()"> -
                                                        {{categoria.federada === 'ACTIVADA' ? 'Federada':'No
                                                        federada'}}</span>
                                                </option>

                                                <option *ngIf="obtenerDesafioSinCupo(categoria)"
                                                    style="color:red !important;" disabled [value]="categoria.id">
                                                    (DESAFIO SIN
                                                    CUPOS) - {{categoria.nombre}} - {{categoria.desafioEntity.nombre}} -
                                                    {{categoria.modo}}<span *ngIf="mostrarIdUCIPersona1()"> -
                                                        {{categoria.federada === 'ACTIVADA' ? 'Federada':'No
                                                        federada'}}</span>
                                                </option>

                                                <option
                                                    *ngIf="obtenerUltimaConfiguracionCat(categoria).estado === 'DISPONIBLE' && !obtenerDesafioSinCupo(categoria)"
                                                    [value]="categoria.id">{{categoria.nombre}} -
                                                    {{categoria.desafioEntity.nombre}} - {{categoria.modo}}<span
                                                        *ngIf="mostrarIdUCIPersona1()"> - {{categoria.federada ===
                                                        'ACTIVADA' ? 'Federada':'No federada'}}</span>
                                                </option>
                                            </ng-container>
                                        </select>
                                        <span
                                            *ngIf="formInscripcion.controls['categoria'].invalid && (formInscripcion.controls['categoria'].dirty || formInscripcion.controls['categoria'].touched)"
                                            style="color:red"><b>El campo categoría es obligatorio.</b></span>

                                    </div>
                                </div>

                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label>Team:</label>
                                        <input type="text" formControlName="team" class="form-control"
                                            placeholder="Team">
                                    </div>
                                </div>

                                <ng-container *ngIf="this.evento.solicitar_ciudad === 'ACTIVADO'">
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <label>Región: <span style="color:red">(requerido)</span></label>
                                            <select formControlName="region" class="form-control"
                                                (change)="obtenerCiudades(true)">
                                                <option *ngFor="let region of regiones" [value]="region.id">
                                                    {{region.nombre}}
                                                </option>
                                            </select>
                                            <span
                                                *ngIf="formInscripcion.controls['region'].invalid && (formInscripcion.controls['region'].dirty || formInscripcion.controls['region'].touched)"
                                                style="color:red"><b>El campo región es obligatorio.</b></span>

                                        </div>
                                    </div>

                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <label>Ciudad: <span style="color:red">(requerido)</span></label>
                                            <select formControlName="ciudad" class="form-control">
                                                <option *ngFor="let ciudad of ciudades" [value]="ciudad.nombre">
                                                    {{ciudad.nombre}}
                                                </option>
                                            </select>
                                            <span
                                                *ngIf="formInscripcion.controls['ciudad'].invalid && (formInscripcion.controls['ciudad'].dirty || formInscripcion.controls['ciudad'].touched)"
                                                style="color:red"><b>El campo ciudad es obligatorio.</b></span>

                                        </div>
                                    </div>
                                </ng-container>


                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label>Teléfono móvil: <span style="color:red">(requerido)</span></label>
                                        <input type="text" formControlName="phoneNumber" class="form-control"
                                            placeholder="Teléfono móvil" (keyup)="formatPhone()"
                                            (keypress)="validatePhone($event)">
                                        <label>*Nota: El formato es 569XXXXXXXX</label><br>
                                        <span
                                            *ngIf="formInscripcion.controls['phoneNumber'].invalid && (formInscripcion.controls['phoneNumber'].dirty || formInscripcion.controls['phoneNumber'].touched)"
                                            style="color:red"><b>Ingresa un teléfono móvil válido.</b></span>

                                    </div>
                                </div>

                                <div class="col-lg-6" *ngIf="this.evento.solicitar_talla_tricota === 'ACTIVADO'">
                                    <div class="form-group">
                                        <label>Talla tricota/polera: <span style="color:red">(requerido)</span></label>
                                        <select formControlName="talla_tricota" class="form-control">
                                            <option *ngFor="let talla of tallasTricotas" [value]="talla.talla">
                                                {{talla.talla}}
                                            </option>
                                        </select>
                                        <label>*Nota: La tipo de la prenda (polera/tricota) es escogida exclusivamente
                                            por el organizador del evento.</label><br>
                                        <span
                                            *ngIf="formInscripcion.controls['talla_tricota'].invalid && (formInscripcion.controls['talla_tricota'].dirty || formInscripcion.controls['talla_tricota'].touched)"
                                            style="color:red"><b>El talla tricota/polera es obligatorio.</b></span>

                                    </div>
                                </div>

                                <div class="col-lg-6" *ngFor="let campo of camposAdicionalesForm">
                                    <div class="form-group">
                                        <label [for]="campo.key">{{ limpiarLabel(campo.key) }}:
                                            <span *ngIf="obtenerCampoAdicional(campo.key).campoAdicional.obligatorio"
                                                style="color:red">(requerido)</span>
                                            <span
                                                *ngIf="!obtenerCampoAdicional(campo.key).campoAdicional.obligatorio">(opcional)</span>
                                        </label>
                                        <input *ngIf="obtenerCampoAdicional(campo.key).campoAdicional.tipo === 'TEXTO'"
                                            type="text" [id]="campo.key"
                                            [formControl]="formInscripcion.get('campos_adicionales').get(campo.key)"
                                            class="form-control">
                                        <input
                                            *ngIf="obtenerCampoAdicional(campo.key).campoAdicional.tipo === 'NUMERICO'"
                                            type="number" [id]="campo.key"
                                            [formControl]="formInscripcion.get('campos_adicionales').get(campo.key)"
                                            class="form-control">
                                        <select *ngIf="obtenerCampoAdicional(campo.key).campoAdicional.tipo === 'LISTA'"
                                            [formControl]="formInscripcion.get('campos_adicionales').get(campo.key)"
                                            class="form-control">
                                            <option *ngFor="let opcion of obtenerOpcionesLista(campo.key)"
                                                [value]="opcion">
                                                {{opcion}}
                                            </option>
                                        </select>
                                        <span
                                            *ngIf="obtenerCampoAdicional(campo.key).campoAdicional.obligatorio && isFieldInvalid(campo.key, formInscripcion)"
                                            style="color:red"><b>El campo {{limpiarLabel(campo.key)}} es
                                                obligatorio.</b></span>
                                    </div>

                                </div>
                            </div>


                        </ng-container>
                    </div>
                </div>

                <div class="container" *ngIf="categoria!== null && categoria.modo === 'duplas'">
                    <div class="book-table-wrap">
                        <div class="section-title">
                            <h2><span *ngIf="categoria!== null && categoria.modo === 'duplas'">Persona 2 -
                                </span>Inscripción a evento: {{evento.nombre}}</h2>
                        </div>

                        <ng-container [formGroup]="formInscripcionPersona2">
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label>Nombres: <span style="color:red">(requerido)</span></label>
                                        <input type="text" formControlName="nombre" class="form-control"
                                            placeholder="Nombre">
                                        <span
                                            *ngIf="formInscripcionPersona2.controls['nombre'].invalid && (formInscripcionPersona2.controls['nombre'].dirty || formInscripcionPersona2.controls['nombre'].touched)"
                                            style="color:red"><b>El campo nombre es obligatorio.</b></span>
                                    </div>
                                </div>

                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label>Apellidos: <span style="color:red">(requerido)</span></label>
                                        <input type="text" formControlName="apellido" class="form-control"
                                            placeholder="Apellidos">
                                        <span
                                            *ngIf="formInscripcionPersona2.controls['apellido'].invalid && (formInscripcionPersona2.controls['apellido'].dirty || formInscripcionPersona2.controls['apellido'].touched)"
                                            style="color:red"><b>El campo apellidos es obligatorio.</b></span>

                                    </div>
                                </div>

                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label>Correo electrónico: <span style="color:red">(requerido)</span></label>
                                        <input type="email" formControlName="email" class="form-control"
                                            placeholder="Correo electrónico">
                                        <label>*Nota: Debes ingresar un correo electrónico válido ya que te llegará el
                                            comprobante de inscripción</label><br>
                                        <span
                                            *ngIf="formInscripcion.controls['email'].invalid && (formInscripcion.controls['email'].dirty || formInscripcion.controls['email'].touched)"
                                            style="color:red"><b>El campo correo electrónico es obligatorio.</b></span>


                                    </div>
                                </div>

                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label>Soy Federado: <span style="color:red">(requerido)</span></label>
                                        <br>
                                        <label>
                                            <input type="radio" value="Federado" formControlName="federado">&nbsp;
                                            <span>Federado</span>
                                        </label>
                                        <br>
                                        <label>
                                            <input type="radio" value="No federado" formControlName="federado">&nbsp;
                                            <span>No Federado</span>
                                        </label>
                                        <label>*Nota: Al seleccionar federado deberás ingresar el campo ID
                                            UCI.</label><br>
                                    </div>
                                </div>

                                <div class="col-lg-6" *ngIf="mostrarIdUCIPersona2()">
                                    <div class="form-group">
                                        <label>ID UCI: <span style="color:red">(requerido)</span></label>
                                        <input type="number" maxlength="11" formControlName="id_uci"
                                            class="form-control" placeholder="ID UCI">
                                    </div>
                                </div>

                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label>Fecha de nacimiento: <span style="color:red">(requerido)</span></label>
                                        <ng-container *ngIf="mostrarRestriccionEdad">&nbsp; <i
                                                class='bx-flashing bx bxs-error-alt'
                                                style="font-size: 20px; color: red"></i> &nbsp; <span
                                                class="restriccion-form" (click)="showRestriccion()">Ver
                                                restricción</span></ng-container>
                                        <input type="date" (change)="changeFechaInscripcion2()"
                                            formControlName="fecha_nacimiento" class="form-control"
                                            placeholder="Fecha de nacimiento">
                                        <span style="color:red" *ngIf="errorRestriccionEdad">La sumatoria de las edades
                                            de los 2 participantes debe ser mayor o igual a
                                            {{categoria.sumatoriaMinima}} y menor o igual a
                                            {{categoria.sumatoriaMaxima}}</span><br>
                                        <span
                                            *ngIf="formInscripcionPersona2.controls['fecha_nacimiento'].invalid && (formInscripcionPersona2.controls['fecha_nacimiento'].dirty || formInscripcionPersona2.controls['fecha_nacimiento'].touched)"
                                            style="color:red"><b>El campo fecha de nacimiento es obligatorio.</b></span>

                                    </div>
                                </div>

                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label *ngIf="getTipoDocumento2() === 'RUT'">RUT: <span
                                                style="color:red">(requerido)</span></label>
                                        <input *ngIf="getTipoDocumento2() === 'RUT'" type="text" formatRut
                                            formControlName="rut" class="form-control" placeholder="RUT">
                                        <span
                                            *ngIf="getTipoDocumento2() === 'RUT' && formInscripcionPersona2.controls['rut'].invalid && (formInscripcionPersona2.controls['rut'].dirty || formInscripcionPersona2.controls['rut'].touched)"
                                            style="color:red"><b>Debes ingresar un rut válido.</b></span>

                                        <label *ngIf="getTipoDocumento2() === 'passport'">Pasaporte: <span
                                                style="color:red">(requerido)</span></label>
                                        <input *ngIf="getTipoDocumento2() === 'passport'" type="text"
                                            formControlName="rut" class="form-control" placeholder="DNI">
                                        <span
                                            *ngIf="getTipoDocumento2() === 'passport' && formInscripcionPersona2.controls['rut'].invalid && (formInscripcionPersona2.controls['rut'].dirty || formInscripcionPersona2.controls['rut'].touched)"
                                            style="color:red"><b>Debes ingresar un pasaporte.</b></span>

                                        <input type="checkbox" [checked]="extranjero2"
                                            (change)="changeExtranjero2(!extranjero2)"><label>&nbsp;&nbsp;Soy
                                            extranjero</label>

                                    </div>




                                </div>

                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label>Sexo: <span style="color:red">(requerido)</span></label>
                                        <select formControlName="sexo" class="form-control">
                                            <option value="masculino">Masculino</option>
                                            <option value="femenino">Femenino</option>
                                        </select>
                                        <span
                                            *ngIf="formInscripcionPersona2.controls['sexo'].invalid && (formInscripcionPersona2.controls['sexo'].dirty || formInscripcionPersona2.controls['sexo'].touched)"
                                            style="color:red"><b>El campo sexo es obligatorio.</b></span>

                                    </div>
                                </div>

                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label>Desafío: <span style="color:red">(requerido)</span></label>
                                        <select formControlName="desafio" class="form-control">
                                            <option value="0">Selecciona desafío</option>

                                            <ng-container *ngFor="let desafio of desafios">
                                                <option *ngIf="!obtenerDesafioPorDesafioSinCupo(desafio)"
                                                    [value]="desafio.id">{{desafio.nombre}}
                                                </option>

                                                <option *ngIf="obtenerDesafioPorDesafioSinCupo(desafio)" disabled
                                                    [value]="desafio.id">SIN CUPO - {{desafio.nombre}}
                                                </option>
                                            </ng-container>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label>Categoria: <span style="color:red">(requerido)</span></label>
                                        <select formControlName="categoria" class="form-control">
                                            <ng-container *ngFor="let categoria of obtenerCategoriasFiltradasDupla()">
                                                <option
                                                    *ngIf="obtenerUltimaConfiguracionCat(categoria).estado === 'NO DISPONIBLE' && !obtenerDesafioSinCupo(categoria)"
                                                    style="color:red !important;" disabled [value]="categoria.id">(NO
                                                    DISPONIBLE) - {{categoria.nombre}} -
                                                    {{categoria.desafioEntity.nombre}} - {{categoria.modo}}<span
                                                        *ngIf="mostrarIdUCIPersona2()"> - {{categoria.federada ===
                                                        'ACTIVADA' ? 'Federada':'No federada'}}</span>
                                                </option>

                                                <option
                                                    *ngIf="obtenerUltimaConfiguracionCat(categoria).estado === 'SIN CUPOS' && !obtenerDesafioSinCupo(categoria)"
                                                    style="color:red !important;" disabled [value]="categoria.id">(SIN
                                                    CUPOS) - {{categoria.nombre}} - {{categoria.desafioEntity.nombre}} -
                                                    {{categoria.modo}}<span *ngIf="mostrarIdUCIPersona2()"> -
                                                        {{categoria.federada === 'ACTIVADA' ? 'Federada':'No
                                                        federada'}}</span>
                                                </option>

                                                <option *ngIf="obtenerDesafioSinCupo(categoria)"
                                                    style="color:red !important;" disabled [value]="categoria.id">
                                                    (DESAFIO SIN
                                                    CUPOS) - {{categoria.nombre}} - {{categoria.desafioEntity.nombre}} -
                                                    {{categoria.modo}}<span *ngIf="mostrarIdUCIPersona2()"> -
                                                        {{categoria.federada === 'ACTIVADA' ? 'Federada':'No
                                                        federada'}}</span>
                                                </option>

                                                <option
                                                    *ngIf="obtenerUltimaConfiguracionCat(categoria).estado === 'DISPONIBLE' && !obtenerDesafioSinCupo(categoria)"
                                                    [value]="categoria.id">{{categoria.nombre}} -
                                                    {{categoria.desafioEntity.nombre}} - {{categoria.modo}}<span
                                                        *ngIf="mostrarIdUCIPersona2()"> - {{categoria.federada ===
                                                        'ACTIVADA' ? 'Federada':'No federada'}}</span>
                                                </option>
                                            </ng-container>
                                        </select>
                                        <span
                                            *ngIf="formInscripcionPersona2.controls['categoria'].invalid && (formInscripcionPersona2.controls['categoria'].dirty || formInscripcionPersona2.controls['categoria'].touched)"
                                            style="color:red"><b>El campo categoría es obligatorio.</b></span>

                                    </div>
                                </div>

                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label>Team:</label>
                                        <input type="text" formControlName="team" class="form-control"
                                            placeholder="Team">
                                    </div>
                                </div>

                                <ng-container *ngIf="this.evento.solicitar_ciudad === 'ACTIVADO'">
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <label>Región: <span style="color:red">(requerido)</span></label>
                                            <select formControlName="region" class="form-control"
                                                (change)="obtenerCiudades(true)">
                                                <option *ngFor="let region of regiones" [value]="region.id">
                                                    {{region.nombre}}
                                                </option>
                                            </select>
                                            <span
                                                *ngIf="formInscripcionPersona2.controls['region'].invalid && (formInscripcionPersona2.controls['region'].dirty || formInscripcionPersona2.controls['region'].touched)"
                                                style="color:red"><b>El campo región es obligatorio.</b></span>

                                        </div>
                                    </div>

                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <label>Ciudad: <span style="color:red">(requerido)</span></label>
                                            <select formControlName="ciudad" class="form-control">
                                                <option *ngFor="let ciudad of ciudades" [value]="ciudad.nombre">
                                                    {{ciudad.nombre}}
                                                </option>
                                            </select>
                                            <span
                                                *ngIf="formInscripcionPersona2.controls['ciudad'].invalid && (formInscripcionPersona2.controls['ciudad'].dirty || formInscripcionPersona2.controls['ciudad'].touched)"
                                                style="color:red"><b>El campo ciudad es obligatorio.</b></span>

                                        </div>
                                    </div>
                                </ng-container>

                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label>Teléfono móvil: <span style="color:red">(requerido)</span></label>
                                        <input type="text" formControlName="phoneNumber" class="form-control"
                                            placeholder="Teléfono móvil" (keyup)="formatPhoneDupla()"
                                            (keypress)="validatePhone($event)">
                                        <label>*Nota: El formato es 569XXXXXXXX</label><br>
                                        <span
                                            *ngIf="formInscripcionPersona2.controls['phoneNumber'].invalid && (formInscripcionPersona2.controls['phoneNumber'].dirty || formInscripcionPersona2.controls['phoneNumber'].touched)"
                                            style="color:red"><b>Ingresa un teléfono móvil válido.</b></span>

                                    </div>
                                </div>

                                <div class="col-lg-6" *ngIf="this.evento.solicitar_talla_tricota === 'ACTIVADO'">
                                    <div class="form-group">
                                        <label>Talla tricota/polera: <span style="color:red">(requerido)</span></label>
                                        <select formControlName="talla_tricota" class="form-control">
                                            <option *ngFor="let talla of tallasTricotas" [value]="talla.talla">
                                                {{talla.talla}}
                                            </option>
                                        </select>
                                        <label>*Nota: La tipo de la prenda (polera/tricota) es escogida exclusivamente
                                            por el organizador del evento.</label><br>
                                        <span
                                            *ngIf="formInscripcionPersona2.controls['talla_tricota'].invalid && (formInscripcionPersona2.controls['talla_tricota'].dirty || formInscripcionPersona2.controls['talla_tricota'].touched)"
                                            style="color:red"><b>El talla tricota/polera es obligatorio.</b></span>

                                    </div>
                                </div>

                                
                                <div class="col-lg-6" *ngFor="let campo of camposAdicionalesForm">
                                    <div class="form-group">
                                        <label [for]="campo.key">{{ limpiarLabel(campo.key) }}:
                                            <span *ngIf="obtenerCampoAdicional(campo.key).campoAdicional.obligatorio"
                                                style="color:red">(requerido)</span>
                                            <span
                                                *ngIf="!obtenerCampoAdicional(campo.key).campoAdicional.obligatorio">(opcional)</span>
                                        </label>
                                        <input *ngIf="obtenerCampoAdicional(campo.key).campoAdicional.tipo === 'TEXTO'"
                                            type="text" [id]="campo.key"
                                            [formControl]="formInscripcionPersona2.get('campos_adicionales').get(campo.key)"
                                            class="form-control">
                                        <input
                                            *ngIf="obtenerCampoAdicional(campo.key).campoAdicional.tipo === 'NUMERICO'"
                                            type="number" [id]="campo.key"
                                            [formControl]="formInscripcionPersona2.get('campos_adicionales').get(campo.key)"
                                            class="form-control">
                                        <select *ngIf="obtenerCampoAdicional(campo.key).campoAdicional.tipo === 'LISTA'"
                                            [formControl]="formInscripcionPersona2.get('campos_adicionales').get(campo.key)"
                                            class="form-control">
                                            <option *ngFor="let opcion of obtenerOpcionesLista(campo.key)"
                                                [value]="opcion">
                                                {{opcion}}
                                            </option>
                                        </select>
                                        <span
                                            *ngIf="obtenerCampoAdicional(campo.key).campoAdicional.obligatorio && isFieldInvalid(campo.key, formInscripcionPersona2)"
                                            style="color:red"><b>El campo {{limpiarLabel(campo.key)}} es
                                                obligatorio.</b></span>
                                    </div>

                                </div>

                            </div>


                        </ng-container>
                    </div>
                </div>

                <ng-container *ngFor="let adicionalSeleccionado of adicionalesSeleccionados">
                    <hr>
                    <app-adicional-form [id]="adicionalSeleccionado.id" [eventoId]="adicionalSeleccionado.eventoId"
                        [adicionalId]="adicionalSeleccionado.adicionalId" nombreBoton="Confirmar"
                        (adicionalForm)="confirmarAdicional($event)" [lock]="obtenerValidado(adicionalSeleccionado.id)"
                        (deleteItem)="eliminar($event)"></app-adicional-form>

                </ng-container>

                <section class="cart-area" *ngIf="obtenerAdicionalValidado().length>0">
                    <div class="container">
                        <div class="cart-wrap">
                            <h2>Productos adicionales agregados con éxito</h2>
                            <br>
                            <table class="table">
                                <thead class="thead">
                                    <tr>
                                        <th class="table-head" scope="col">Producto adicional</th>
                                        <th class="table-head" scope="col">Datos registrados</th>
                                        <th class="table-head" scope="col"></th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr *ngFor="let adicional of obtenerAdicionalValidado()">
                                        <td>{{adicional.formAdicional.eventoAdicional.adicional.nombre}}</td>
                                        <td><span [innerHTML]="getAdicionalTexto(adicional.formAdicional)"></span></td>
                                        <td>
                                            <span (click)="eliminar(adicional.id)"
                                                class="delete-notebook btn cmn-btn"><i class='bx bx-trash'></i>
                                                Eliminar</span>
                                            <span (click)="eliminar(adicional.id)" class="delete-smartphone"><i
                                                    class='bx bx-trash'></i> Eliminar</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </section>


                <br>
                <section class="cart-area"
                    *ngIf="adicionales.length > 0 && this.categoria !== null && this.categoria.valor !== 0">
                    <div class="container">
                        <div class="cart-wrap">
                            <h2>Productos adicionales</h2>

                            <table class="table">
                                <thead class="thead">
                                    <tr>
                                        <th class="table-head" scope="col">Producto adicional</th>
                                        <th class="table-head" scope="col"></th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr *ngFor="let adicional of adicionales">
                                        <td>{{adicional.adicional.nombre}}</td>
                                        <td>
                                            <span (click)="selectAdicional(adicional)"
                                                class="delete-notebook btn cmn-btn"><i class='bx bx-pointer'></i>
                                                Añadir</span>
                                            <span (click)="selectAdicional(adicional)" class="delete-smartphone"><i
                                                    class='bx bx-pointer'></i> Añadir</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </section>
            </div>
            <br>
            <div class="col-lg-3 col-md-12">
                <div class="container">
                    <div class="book-table-wrap" style="padding: 60px 20px 65px;">
                        <div class="section-title">
                            <span><b>Detalles de la orden</b></span>
                            <hr>
                            <table width="100%">
                                <thead>
                                    <tr>
                                        <th colspan="2">
                                            <span *ngIf="categoria === null">Sin categoría seleccionada</span>
                                            <span *ngIf="categoria !== null">{{categoria.nombre}} -
                                                {{categoria.desafioEntity.nombre}} - {{categoria.modo}}</span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <br>
                                    <tr>
                                        <td>1 tickets</td>
                                        <th>
                                            <span *ngIf="categoria === null">CLP $-</span>
                                            <span *ngIf="categoria !== null">CLP ${{getMontoFinal()}}</span>
                                        </th>
                                    </tr>
                                    <tr>
                                        <td>Comisión</td>
                                        <th>
                                            <span *ngIf="categoria === null">CLP $-</span>
                                            <span *ngIf="categoria !== null">CLP ${{getMontoCargos()}}</span>
                                        </th>
                                    </tr>
                                    <tr *ngIf="obtenerAdicionalValidado().length>0">
                                        <td>
                                            <hr>
                                        </td>
                                        <th>
                                            <hr>
                                        </th>
                                    </tr>
                                    <tr *ngIf="obtenerAdicionalValidado().length>0">
                                        <th colspan="2">
                                            <span>Productos adicionales</span>
                                        </th>
                                    </tr>
                                    <br *ngIf="obtenerAdicionalValidado().length>0">
                                    <ng-container *ngIf="obtenerAdicionalValidado().length>0">
                                        <ng-container *ngFor="let adicional of obtenerAdicionalValidado()">
                                            <tr>
                                                <td>{{adicional.formAdicional.eventoAdicional.adicional.nombre}}</td>
                                                <th>
                                                    <span>CLP ${{adicional.formAdicional.eventoAdicional.precio}}</span>
                                                </th>
                                            </tr>
                                            <tr>
                                                <td>Comisión</td>
                                                <th>
                                                    <span>CLP ${{adicional.formAdicional.eventoAdicional.precio *
                                                        adicional.formAdicional.porcentaje_cobro}}</span>
                                                </th>
                                            </tr>
                                        </ng-container>
                                    </ng-container>

                                    <tr>
                                        <td>
                                            <hr>
                                        </td>
                                        <th>
                                            <hr>
                                        </th>
                                    </tr>
                                    <ng-container *ngIf="cupon !== null">
                                        <tr>
                                            <td>Cupón</td>
                                            <th>
                                                <span style="color:red">(-) CLP ${{(getMontoCargos() + getMontoFinal())
                                                    * (cupon.porcentaje_descuento)}}</span>
                                            </th>
                                        </tr>
                                    </ng-container>
                                    <tr>
                                        <td>Total</td>
                                        <th>
                                            <span *ngIf="categoria !== null">CLP ${{((getMontoCargos() +
                                                getMontoFinal()) - (getMontoCargos() + getMontoFinal()) * (cupon=== null
                                                ? 0 : cupon.porcentaje_descuento) + (getMontoFinalAdicionales() +
                                                getMontoCargosAdicionales()))}}</span>
                                            <span *ngIf="categoria === null">CLP $-</span>
                                        </th>
                                    </tr>
                                </tbody>
                            </table>
                            <hr *ngIf="evento.mostrar_validador_cupon === 'ACTIVADO'">
                            <ng-container [formGroup]="formCupon" *ngIf="evento.mostrar_validador_cupon === 'ACTIVADO'">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div>
                                            <label>¿Tienes un código de descuento?:
                                                <span>(opcional)</span></label><br><br>
                                            <input *ngIf="cupon===null" type="text" formControlName="codigo"
                                                class="form-control" placeholder="Código Cupón"><br>
                                            <span *ngIf="cupon!==null">#{{cupon.codigo}} - {{cupon.nombre_cupon}} -
                                                {{cupon.porcentaje_descuento*100}}% Descuento</span>

                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-12">
                                        <button *ngIf="cupon===null" (click)="validarCupon()"
                                            class="btn cmn-btn">Validar Cupón</button>

                                    </div>
                                </div>

                            </ng-container>
                            <hr>
                            <div *ngIf="categoria!== null && categoria.modo !== 'duplas'">
                                <button (click)="incripcionClick()" class="btn cmn-btn">Continuar</button>
                            </div>
                            <div *ngIf="categoria!== null && categoria.modo === 'duplas'">
                                <button *ngIf="!errorRestriccionEdad" (click)="incripcionClick()"
                                    class="btn cmn-btn">Continuar</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>

</div>

<app-footer-style-two></app-footer-style-two>

<app-preloader *ngIf="loading || loadingInfoInicial"></app-preloader>