// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    api: 'https://bikerace-qa.herokuapp.com',
    //api: 'http://localhost:3000',
    urlRetornoCompra: 'https://bikerace-user-qa.netlify.app/#/pago',
    urlBikeraceWeb: 'https://bikerace-user-qa.netlify.app/',
    secretUser : '19072350k',
    idUser: 1,
    emailUser : 'fsotomayor30@gmail.com',
    uuidAnalytics : 'G-YCZRPY65MX',
    urlPanelAdministracion: 'https://qa.bikerace.cl/',
    recaptchaKey: '6Ld4wlwjAAAAAEYrLGYgvGAdCe-WT97_JcWRavCR',
    adSenseClient: 'ca-pub-2211414242381764',
    clientIdPaypal: 'AQajhQfuFPzg8uyHKto_La73Sb-gIlZauasDo0-GiD-pD9k51VXnNX1NgM6uCbmYSljqMyVT9pnCTJg_',
    tokenMapbox: 'pk.eyJ1IjoiZGV2ZWxvcGVyYmlrZXJhY2UiLCJhIjoiY2xkM2Y4dW13MDM1dTNucGMxYmxhdHp0aSJ9.Q5LCf3I1yrnIT59fpysGaw' ,
    styleMapbox : 'mapbox://styles/mapbox/satellite-streets-v12',
    zoomMapbox : 15, 
    endpointApiIp : 'https://api.ipify.org?format=json',
    production: false
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
