<app-navbar-style-three></app-navbar-style-three>

<!--<div class="page-title-area page-title-img-one" *ngIf="!loadingInfoInicial">-->
<!--    <div class="container">-->
<!--        <div class="page-title-item">-->
<!--            <h2>{{evento.nombre}}</h2>-->
<!--            <ul>-->
<!--                <li><a routerLink="/">Inicio</a></li>-->
<!--                <li><i class='bx bx-chevron-right' ></i></li>-->
<!--                <li>Categoría</li>-->
<!--                <li><i class='bx bx-chevron-right' ></i></li>-->
<!--                <li>Inscripción</li>-->

<!--            </ul>-->
<!--        </div>-->
<!--    </div>-->
<!--</div>-->

<div class="book-table-area ptb-100"  >
    <div  class="container">
        <div class="book-table-wrap">
            <div class="section-title">
                <h2>Solicitud cambio de categoria</h2>
                <span  style="color:red">NOTA: Esta solicitud llegará al equipo Bikerace, quien la analizará y te notificará la respuesta mediante correo electrónico.</span>
            </div>

            <ng-container [formGroup]="formBusqueda">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="form-group">
                            <label *ngIf="!extranjero">Ingresa aquí tu RUT: <span style="color:red">(requerido)</span></label>
                            <input *ngIf="!extranjero" type="text" formatRut formControlName="rut" class="form-control" placeholder="RUT">
                            <span *ngIf="!extranjero && formBusqueda.controls['rut'].invalid && (formBusqueda.controls['rut'].dirty || formBusqueda.controls['rut'].touched)" style="color:red"><b>Debes ingresar un rut válido.</b></span>

                            <label *ngIf="extranjero">Ingresa aquí tu Pasaporte: <span style="color:red">(requerido)</span></label>
                            <input *ngIf="extranjero" type="text" formControlName="rut" class="form-control" placeholder="Pasaporte">
                            <span *ngIf="extranjero && formBusqueda.controls['rut'].invalid && (formBusqueda.controls['rut'].dirty || formBusqueda.controls['rut'].touched)" style="color:red"><b>Debes ingresar un pasaporte válido.</b></span>
                            <br>
                            <span (click)="changeExtranjero(!extranjero)">
                                <input type="checkbox" [checked]="extranjero" ><label>&nbsp;&nbsp;Soy extranjero</label>
                            </span>

                        </div>
                    </div>
                </div>

                <div  class="text-center">
                    <button (click)="buscarRut()" class="btn cmn-btn">Buscar</button>
                </div>
            </ng-container>


            <ng-container *ngIf="eventos.length>0" [formGroup]="formSolicitud">
                <hr>

                <div class="row">
                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>Evento: <span style="color:red">(requerido)</span></label>
                            <select formControlName="evento" class="form-control" (change)="changeEvento()">
                                <option *ngFor="let evento of eventos" [value]="evento.id">{{evento.nombre}}</option>
                            </select>
                            <span *ngIf="formSolicitud.controls['evento'].invalid && (formSolicitud.controls['evento'].dirty || formSolicitud.controls['evento'].touched)" style="color:red"><b>El campo evento es obligatorio.</b></span>

                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>Selecciona categoria a la que te deseas cambiar <span style="color:red">(requerido)</span></label>
                            <select formControlName="categoria" class="form-control" >
                                <option *ngFor="let categoria of categorias" [value]="categoria.id">{{categoria.nombre}} - {{categoria.desafioEntity.nombre}} - {{categoria.modo}} - {{categoria.federada === 'ACTIVADA' ? 'Federada' : categoria.federada === 'DESACTIVADA' ? 'No federada' : ''}}</option>                            </select>
                            <span *ngIf="formSolicitud.controls['categoria'].invalid && (formSolicitud.controls['categoria'].dirty || formSolicitud.controls['categoria'].touched)" style="color:red"><b>El campo categoría es obligatorio.</b></span>

                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="form-group">
                            <label>Descripción: <span style="color:red">(requerido)</span></label>
                            <textarea placeholder="Ingresa el motivo de tu solicitud . . ." formControlName="descripcion" class="form-control"></textarea>
                            <span *ngIf="formSolicitud.controls['descripcion'].invalid && (formSolicitud.controls['descripcion'].dirty || formSolicitud.controls['descripcion'].touched)" style="color:red"><b>El campo descripción es obligatorio.</b></span>

                        </div>
                    </div>
                </div>

                <div  class="text-center">
                    <button (click)="enviarSolicitud()" class="btn cmn-btn">Enviar solicitud</button>
                </div>
            </ng-container>
        </div>
    </div>

</div>

<app-footer-style-two></app-footer-style-two>

<app-preloader *ngIf="loading" ></app-preloader>
