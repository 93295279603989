import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomeOneComponent} from './components/pages/home-one/home-one.component';
import {AboutComponent} from './components/pages/about/about.component';
import {TermsConditionsComponent} from './components/pages/terms-conditions/terms-conditions.component';
import {ErrorComponent} from './components/pages/error/error.component';
import {FaqComponent} from './components/pages/faq/faq.component';
import {EventDetailsComponent} from './components/pages/event-details/event-details.component';
import {EventsComponent} from './components/pages/events/events.component';
import {InscriptionComponent} from './components/pages/inscription/inscription.component';
import {PaymentComponent} from './components/pages/payment/payment.component';
import {ResultsComponent} from './components/pages/results/results.component';
import {EventResultsComponent} from './components/pages/event-results/event_results.component';
import {NoticiaComponent} from './components/pages/noticias/noticia.component';
import {NoticiaDetailsComponent} from './components/pages/noticias-details/noticia-details.component';
import {ShoppingCartComponent} from './components/pages/shopping-cart/shopping-cart.component';
import {RankingComponent} from './components/pages/ranking/ranking.component';
import {CampeonatoResultsComponent} from './components/pages/campeonato-results/campeonato_results.component';
import {LoginComponent} from './components/pages/login/login.component';
import {ProfileComponent} from './components/pages/profile/profile.component';
import {ChangePasswordComponent} from './components/pages/change-password/change-password.component';
import {InscriptionDetailsComponent} from './components/pages/inscription-details/inscription-details.component';
import {AuthUserGuard} from './guards/auth-user.guard';
import {TransactionsComponent} from './components/pages/transactions/transactions.component';
import {TransactionDetailsComponent} from './components/pages/transaction-details/transaction-details.component';
import {InscriptionsComponent} from './components/pages/inscriptions/inscriptions.component';
import {InscriptionsEventsComponent} from './components/pages/inscriptions-events/inscriptions-events.component';
import {PaymentTransferenciaComponent} from './components/pages/payment-transferencia/payment-transferencia.component';
import {ServiciosComponent} from './components/pages/servicios/servicios.component';
import {FechasDisponiblesComponent} from './components/pages/fechas-disponibles/fechas-disponibles.component';
import {CambioCategoriaComponent} from './components/pages/cambio-categoria/cambio-categoria.component';
import {MisEstadisticasComponent} from "./components/pages/mis-estadisticas/mis-estadisticas.component";
import {EventAdicionalesComponent} from "./components/pages/event-adicionales/event-adicionales.component";
import {AdicionalComponent} from "./components/pages/adicional/adicional.component";
import { RecoverPasswordComponent } from './components/pages/recover-password/recover-password.component';
import { ActualizarPasswordComponent } from './components/pages/actualizar-password/actualizar-password.component';
import { TicketComponent } from './components/pages/ticket/ticket.component';
import { PaymentAppComponent } from './components/pages/payment-app/payment-app.component';
import { SolicitudDevolucionComponent } from './components/pages/solicitud-devolucion/solicitud-devolucion.component';
import { CalificarComponent } from './components/pages/calificar/calificar.component';
import { CalificacionesComponent } from './components/pages/calificaciones/calificaciones.component';
import { VisionMisionComponent } from './components/pages/vision-mision/vision-mision.component';

const routes: Routes = [
    {path: '', component: HomeOneComponent},
    // {path: 'home-two', component: HomeTwoComponent},
    // {path: 'home-three', component: HomeThreeComponent},
    {path: 'nosotros', component: AboutComponent},
    {path: 'vision-mision', component: VisionMisionComponent},
    // {path: 'categorias', component: CategoriesComponent},
    {path: 'inscripcion', component: InscriptionComponent},
    {path: 'fechas-disponibles', component: FechasDisponiblesComponent},
    {path: 'cambio-categoria', component: CambioCategoriaComponent},
    {path: 'solicitud-devolucion', component: SolicitudDevolucionComponent},
    {path: 'calificar', component: CalificarComponent},
    {path: 'calificaciones', component: CalificacionesComponent},

    {path: 'pago', component: PaymentComponent},
    {path: 'pago-app', component: PaymentAppComponent},
    {path: 'pago-transferencia', component: PaymentTransferenciaComponent},
    // {path: 'services', component: ServicesComponent},
    // {path: 'services-details', component: ServicesDetailsComponent},
    {path: 'detalle-inscripcion', component: InscriptionDetailsComponent},
    {path: 'evento', component: EventDetailsComponent},
    {path: 'adicionales-evento', component: EventAdicionalesComponent},
    {path: 'adicional', component: AdicionalComponent},
    // {path: 'blog', component: BlogComponent},
    {path: 'noticias', component: NoticiaComponent},
    // {path: 'blog-details', component: BlogDetailsComponent},
    {path: 'noticia-details', component: NoticiaDetailsComponent},
    // {path: 'food-collection', component: FoodCollectionComponent},
    {path: 'eventos', component: EventsComponent},
    // {path: 'online-order', component: OnlineOrderComponent},
    // {path: 'chefs', component: ChefsComponent},
    {path: 'faq', component: FaqComponent},
    // {path: 'book-table', component: BookTableComponent},
    {path: 'carro', component: ShoppingCartComponent},
    {path: 'servicios', component: ServiciosComponent},
    {path: 'ranking-campeonato', component: CampeonatoResultsComponent},
    {path: 'ranking', component: RankingComponent},
    {path: 'resultados', component: ResultsComponent},
    {path: 'resultados-evento', component: EventResultsComponent},
    // {path: 'checkout', component: CheckoutComponent},
    {path: 'login', component: LoginComponent},
    {path: 'profile', component: ProfileComponent, canActivate: [AuthUserGuard]},
    {path: 'mis-inscripciones', component: InscriptionsComponent, canActivate: [AuthUserGuard]},
    {path: 'mis-estadisticas', component: MisEstadisticasComponent, canActivate: [AuthUserGuard]},
    {path: 'inscripciones', component: InscriptionsEventsComponent},
    {path: 'mis-transacciones', component: TransactionsComponent, canActivate: [AuthUserGuard]},
    {path: 'detalle-transaccion', component: TransactionDetailsComponent, canActivate: [AuthUserGuard]},
    {path: 'change-password', component: ChangePasswordComponent, canActivate: [AuthUserGuard]},
    {path: 'actualizar-password', component: ActualizarPasswordComponent},
    {path: 'recover-password', component: RecoverPasswordComponent},
    // {path: 'coming-soon', component: ComingSoonComponent},
    {path: 'terminos-y-condiciones', component: TermsConditionsComponent},
    // {path: 'privacy-policy', component: PrivacyPolicyComponent},
    {path: 'error', component: ErrorComponent},
    // {path: 'contact', component: ContactComponent},
    {path: 'mi-ticket', component: TicketComponent},
    {path: '**', component: ErrorComponent},

];

@NgModule({
    imports: [RouterModule.forRoot(routes, {relativeLinkResolution: 'legacy' , onSameUrlNavigation: 'reload'})],
    exports: [RouterModule]
})
export class AppRoutingModule {}
