import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-estrellas-calificacion',
  templateUrl: './estrellas-calificacion.component.html',
  styleUrls: ['./estrellas-calificacion.component.scss']
})
export class EstrellasCalificacionComponent implements OnInit {

  stars: boolean[] = Array(5).fill(false);
  currentRating: number = 0;
  hoverRating: number = 0;
  @Output() ratingChanged = new EventEmitter<number>();
  @Input() edit: boolean = true;
  @Input() initValue: number = 0;
  @Input() small: boolean = false;

  ngOnInit(){
    if(!this.edit){
      this.currentRating = this.initValue;
    }
  }
  
  onMouseOver(index: number): void {
    this.hoverRating = index + 1;
  }

  onMouseLeave(): void {
    this.hoverRating = 0;
  }

  onClick(index: number): void {
    if(!this.edit){return;}
    this.currentRating = index + 1;
    this.ratingChanged.emit(this.currentRating); // Emitir el valor seleccionado

  }

}
