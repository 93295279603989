import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Injectable()
export class TransaccionService {

    // Observable refresh list data categorias
    flagRefresh = new BehaviorSubject<any>(null);
    observableData$ = this.flagRefresh.asObservable();

    constructor(private http: HttpClient) { }

    // actualizamos flag
    nextData(flag: boolean) {
        this.flagRefresh.next(flag);
    }


    obtenerTransaccion(idPayment: any) {
        return this.http.get<any>(`${environment.api}/transaccion/paymentId/${idPayment}`);
    }

    obtenerBancosKhipu() {
        return this.http.get<any>(`${environment.api}/transaccion/banksKhipu`);
    }

    obtenerTransaccionWebpay(token: any) {
        return this.http.get<any>(`${environment.api}/transaccion/webpay/token/${token}`);
    }

    obtenerTransaccionesPorUsuario(idUsuario: any) {
        return this.http.get<any>(`${environment.api}/transaccion/usuario-deportista/${idUsuario}`);
    }


    obtenerTransaccionesPorId(id: any) {
        return this.http.get<any>(`${environment.api}/transaccion/${id}`);
    }

    iniciarTransaccionMercadoPago(initTransaction: any) {
        return this.http.post<any>(`${environment.api}/transaccion/init-transaction-mercadopago`, initTransaction);
    }

    iniciarTransaccionKhipu(initTransaction: any) {
        return this.http.post<any>(`${environment.api}/transaccion/init-transaction-khipu`, initTransaction);
    }

    iniciarTransaccionTransferencia(initTransaction: any) {
        return this.http.post<any>(`${environment.api}/transaccion/init-transaction-transferencia`, initTransaction);
    }

    iniciarTransaccionWebpay(initTransaction: any) {
        return this.http.post<any>(`${environment.api}/transaccion/init-transaction-webpay`, initTransaction);
    }


    obtenerTicketPorId(id: any) {
        return this.http.post<any>(`${environment.api}/transaccion/obtener-ticket-inscripcion/transaccion/${id}`, {});
    }
}
