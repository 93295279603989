import { Component, OnInit } from '@angular/core';
import {GoogleAnalytics} from '../../../shared/google-analytics';

@Component({
  selector: 'app-vision-mision',
  templateUrl: './vision-mision.component.html',
  styleUrls: ['./vision-mision.component.scss']
})
export class VisionMisionComponent implements OnInit {

  constructor(
      private googleAnalytics: GoogleAnalytics

  ) { }

  ngOnInit(): void {
      this.googleAnalytics.eventEmitter('vision-mision', 'exitoso', '', 'click', 0);

  }

}
