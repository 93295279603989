<app-navbar-style-three></app-navbar-style-three>

<div class="page-title-area page-title-img-one">
    <div class="container">
        <div class="page-title-item">
            <h2>Competidores</h2>
            <ul>
                <li><a routerLink="/">Inicio</a></li>
                <li><i class='bx bx-chevron-right' ></i></li>
                <li>Competidores</li>
            </ul>
        </div>
    </div>
</div>

<section class="cart-area ptb-100">
    <div class="container">
        <div class="cart-wrap">
            <div class="row">
                <div class="col-lg-6">
                    <div class="form-group">
                        <h2>Buscar por categoría:</h2>
                        <select class="form-control" [(ngModel)]="searchCategoria" (change)="changeSearchCategoria()">
                            <option value="0">Todas</option>
                            <ng-container *ngFor="let categoria of categorias">
                                <option *ngIf="categoria.federada === 'DESACTIVADA'" >{{categoria.nombre}} - {{categoria.desafioEntity.nombre}} - {{categoria.modo}} - {{categoria.sexo}}</option>
                            </ng-container>
                        </select>
                        <!--                        <input (keyup)="changeSearchCategoria()" [(ngModel)]="searchCategoria" type="text" class="form-control" placeholder="Categoría">-->
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="form-group">
                        <h2>Buscar por competidor:</h2>
                        <input (keyup)="changeSearch()" [(ngModel)]="search" type="text" class="form-control" placeholder="Competidor">
                    </div>
                </div>

            </div>
            <br>

           <ng-container *ngFor="let categoria of categoriasConCorredorFilter">
               <ng-container *ngIf="categoria['federada'] === 'No federada'">
                    <h2>Categoría {{categoria['nombre']}} - {{categoria['desafio']}} - {{categoria['modo']}} - {{categoria['sexo']}}</h2>
                   <table class="table" style="table-layout: initial !important">
                       <thead class="thead">
                       <tr>
                           <th class="table-head" scope="col">Competidor</th>
                           <th class="table-head" scope="col">Categoria</th>

                       </tr>
                       </thead>

                       <tbody>
                       <tr *ngFor="let corredor of categoria.corredores">
                           <td>{{corredor.nombre}} {{corredor.apellido}} <br><span style="font-size: 12px; font-weight: bold; color: grey">{{corredor.team}}</span></td>
                           <td>{{categoria.nombre}} - {{categoria.modo}} - {{categoria.sexo}}</td>
                       </tr>
                       </tbody>
                   </table>
                   <br>
                   <br>
                   <br>
               </ng-container>

            <ng-container  *ngIf="categoria['federada'] === 'Federada' && validarCategoriaFederada(categoria)">
                <h2>Categoría {{categoria['nombre']}} - {{categoria['desafio']}} - {{categoria['modo']}} - {{categoria['sexo']}}</h2>
                <table class="table" style="table-layout: initial !important">
                    <thead class="thead">
                    <tr>
                        <th class="table-head" scope="col">Competidor</th>
                        <th class="table-head" scope="col">Categoria</th>

                    </tr>
                    </thead>

                    <tbody>
                    <tr *ngFor="let corredor of categoria.corredores">
                        <td>{{corredor.nombre}} {{corredor.apellido}} <br><span style="font-size: 12px; font-weight: bold; color: grey">{{corredor.team}}</span></td>
                        <td>{{categoria.nombre}} - {{categoria.modo}} - {{categoria.sexo}}</td>
                    </tr>
                    </tbody>
                </table>
                <br>
                <br>
                <br>
            </ng-container>

           </ng-container>

        </div>
    </div>
</section>

<app-footer-style-two></app-footer-style-two>

<app-preloader *ngIf="loading" ></app-preloader>

