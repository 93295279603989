import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Injectable()
export class EventoService {

    // Observable refresh list data categorias
    flagRefresh = new BehaviorSubject<any>(null);
    observableData$ = this.flagRefresh.asObservable();

    constructor(private http: HttpClient) { }

    // actualizamos flag
    nextData(flag: boolean) {
        this.flagRefresh.next(flag);
    }

    obtenerEventosFinalizados() {
        return this.http.get<any>(`${environment.api}/evento/estado/finalizados`);
    }

    obtenerEventosVisibles(query: string) {
        return this.http.get<any>(`${environment.api}/evento/estado/visibles?query=${query}`);
    }

    obtenerEventosPorCorredorRutYEstado(rut: string, estados: string) {
        return this.http.get<any>(`${environment.api}/evento/corredor/rut/${rut}/estado/${estados}`);
    }

    obtenerEventosConLimiteVisibles(take: number) {
        return this.http.get<any>(`${environment.api}/evento/take/${take}/visibles`);
    }

    obtenerConfiguracionDistanciaPorEvento(id: number) {
        return this.http.get<any>(`${environment.api}/evento/configuracion-distancias/evento/${id}`);
    }

    obtenerEventoById(id: number) {
        return this.http.get<any>(`${environment.api}/evento/${id}`);
    }


    obtenerCamposAdicionalesPorEventoId(id: number) {
        return this.http.get<any>(`${environment.api}/evento/${id}/campos-adicionales`);
    }


    obtenerDesafiosPorEventoId(id: number) {
        return this.http.get<any>(`${environment.api}/evento/${id}/desafios`);
    }

    obtenerAdicionalesByEventoId(id: number) {
        return this.http.get<any>(`${environment.api}/evento/${id}/eventos-adicionales/activados`);
    }

    obtenerAdicionalPorId(id: number) {
        return this.http.get<any>(`${environment.api}/evento/evento-adicional/${id}`);
    }

    obtenerEventosAdicionalesPorEvento(id: number) {
        return this.http.get<any>(`${environment.api}/evento/${id}/eventos-adicionales`);
    }

    obtenerEventoSimplifyById(id: number) {
        return this.http.get<any>(`${environment.api}/evento/simplify/${id}`);
    }

    obtenerPreventasPorEvento(id: number) {
        return this.http.get<any>(`${environment.api}/evento/${id}/preventas`);
    }
}
