import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-banner-custom',
  templateUrl: './banner-custom.component.html',
  styleUrls: ['./banner-custom.component.scss']
})
export class BannerCustomComponent implements OnInit {

    @Input() public urlBanner;
    public urlBannerSanitizer;
    @Input() public eventName = '';
    @Input() public navigationOne = '';
    @Input() public routerOne = '';
    @Input() public navigationTwo = '';
    constructor(
        private sanitizer: DomSanitizer
    ) {

    }

    ngOnInit(): void {
        this.urlBannerSanitizer = this.sanitizer.bypassSecurityTrustStyle(`url("${this.urlBanner}")`);

    }


}
