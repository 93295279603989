import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { interval } from 'rxjs/internal/observable/interval';
import { map } from 'rxjs/internal/operators/map';
import { LocalStorageService } from 'src/app/shared/storage';
import Swal from 'sweetalert2';

@Component({
  selector: 'ctimer-countdown-timer',
  template: `<div *ngIf="show === true" (click)="irAlCarro()" id="toTopTimer" class="back-to-top-btn-timer"><i class="bx bxs-timer"></i><span style="font-size: 15px;">Te queda {{ time.hours }}:{{ time.minutes }}:{{ time.seconds }} para finalizar tu inscripción</span> </div>  `,
  styles: [``],
})
export class CountdownTimerComponent implements OnInit {
  time!: {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;  
  };
  @Input() finishDateString: string = '';
  finishDate: Date = new Date();
  show = false;
  observable;
  constructor(private localStorageAs: LocalStorageService,
    private router: Router,
) { }

  ngOnInit(): void {
    // Inicializamos el momento que falta hasta llegaral tiempo objetivo con valores en 0
    this.time = {
      days: 0, hours: 0, minutes: 0, seconds: 0
    };
    // Creamos la fecha a partir de la fecha en formato string AAAA-MM-dd HH:mm:ss
    //this.finishDate = new Date(this.finishDateString); 

    this.localStorageAs.watch('carro_compra').subscribe(carroCompra => {
      if (carroCompra !== null){
          const carroDeCompra = JSON.parse(carroCompra);
          if(carroDeCompra.fecha && (carroDeCompra.transacciones.length > 0 || carroDeCompra.adicionales.length > 0)){
            var fecha = new Date(carroDeCompra.fecha);
             var fechaNumber = fecha.setMinutes(fecha.getMinutes() + 15);
              this.finishDate = new Date(fechaNumber);
              this.show = true;
              this.initObservable();              

          }else{
            this.show = false;
            this.stopObservable();
          }
      }else{
        this.show = false;
            this.stopObservable();
      }

  });

    
  }


  initObservable(){
    this.observable = this.start().subscribe((_) => {
    });
  }

  stopObservable(){
    if(this.observable !== undefined){
      this.observable.unsubscribe();
    }
    this.show = false;
  }

  updateTime() {
    const now = new Date();
    const diff = this.finishDate.getTime() - now.getTime();
    
    if(diff<=0){
      this.show = false;
      this.time = {
        hours: 0,
        minutes: 0,
        seconds: 0,
        days: 0
      }
      this.stopObservable();
      Swal.fire({
        title: 'Compra expirada',
        text: "Se ha acabado el tiempo para la realización de tu compra, debes realizar tu inscripción nuevamente",
        icon: 'warning',
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ok',
      }).then((result) => {
        this.localStorageAs.remove('carro_compra');
        this.router.navigate(['/']);
      })
    }

    // Cálculos para sacar lo que resta hasta ese tiempo objetivo / final
    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    const hours = Math.floor(diff / (1000 * 60 * 60));
    const mins = Math.floor(diff / (1000 * 60));
    const secs = Math.floor(diff / 1000);
    
    // La diferencia que se asignará para mostrarlo en la pantalla
    this.time.days = days;
    this.time.hours = hours - days * 24;
    this.time.minutes = mins - hours * 60;
    this.time.seconds = secs - mins * 60;
  }
  
  // Ejecutamos la acción cada segundo, para obtener la diferencia entre el momento actual y el objetivo
  start() {
    return interval(1000).pipe(
      map((x: number) => {
        this.updateTime();
        return x;
      })
    );
  }

  irAlCarro(){
    this.router.navigate(['/carro']);
  }
}