<app-navbar-style-three></app-navbar-style-three>

<div class="services-details-area detail-inscription pt-100 pb-70">
    <div class="container" >
        <div class="row">
            <div class="col-lg-3" *ngIf="!loading">
                <div class="services-details-item">
                    <div class="services-details-order" *ngIf="corredor.transaccion.estado === 'APROBADO'" style="background-color: green">
                        <h3>Inscripción</h3>
                        <span>#{{corredor.id}}</span>
                        <ngx-qrcode
                        [elementType]="elementType"
                        [value]="url"
                        cssClass="aclass"
                        [errorCorrectionLevel]="errorCorrectionLevel">
                        </ngx-qrcode>
                        <br>
                    </div>
                    <div class="services-details-order" *ngIf="corredor.transaccion.estado === 'PENDIENTE'" style="background-color: red">
                        <h3>Inscripción</h3>
                        <span>#{{corredor.id}}</span>
                        <ngx-qrcode
                            [elementType]="elementType"
                            [value]="url"
                            cssClass="aclass"
                            [errorCorrectionLevel]="errorCorrectionLevel">
                        </ngx-qrcode>
                        <br>
                    </div>
                    <div class="services-details-order" *ngIf="corredor.transaccion.estado === 'REVISION_TRANSFERENCIA'" style="background-color: orange">
                        <h3>Inscripción</h3>
                        <span>#{{corredor.id}}</span>
                        <ngx-qrcode
                            [elementType]="elementType"
                            [value]="url"
                            cssClass="aclass"
                            [errorCorrectionLevel]="errorCorrectionLevel">
                        </ngx-qrcode>
                        <br>
                    </div>

                    <div class="services-details-order" *ngIf="corredor.transaccion.estado === 'EXPIRADA'" style="background-color: red">
                        <h3>Inscripción</h3>
                        <span>#{{corredor.id}}</span>
                        <ngx-qrcode
                            [elementType]="elementType"
                            [value]="url"
                            cssClass="aclass"
                            [errorCorrectionLevel]="errorCorrectionLevel">
                        </ngx-qrcode>
                        <br>
                    </div>
                </div>
            </div>

            <div class="col-lg-9">

                <div class="container" *ngIf="!loading">


                    <div class="ticket airline">
                        <div class="top">
                            <img [src]="corredor.evento.url_foto" style="height: 100%;
                            width: 100%;border-top-right-radius: 25px;
                    border-top-left-radius: 25px;">
                        </div>
                        <div class="bottom">
                            <div class="column">
                                <div class="row row-1">
                                    <p><span>Evento</span>{{corredor.evento.nombre}}</p>
                                </div>
                                <div class="row row-2">
                                  <p><span>Deportista</span>{{corredor.nombre}} {{corredor.apellido}}</p>
                                  <p class="row--right"><span>DNI</span>{{corredor.rut}}</p>
                              </div>
                              <div class="row row-2">
                                  <p><span>Categoría</span>{{corredor.categoria.nombre}}</p>
                                  <p class="row--right"><span>Desafio</span>{{corredor.categoria.desafioEntity.nombre}}</p>
                              </div>
                                <div class="row row-2">
                                    <p><span>Fecha</span>{{corredor.evento.fecha_evento | date : 'dd-MM-yyyy' : 'UTC'}}</p>
                                    <p class="row--right"><span>Hr. citación</span>{{corredor.evento.hora_citacion}}</p>
                                </div>
                                <div class="row row-4">
                                    <p><span>Ubicación</span>{{corredor.evento.lugar}}</p>
                                </div>
                    
                              
                            </div>
                            
                        </div>
                    </div>
                    
                    </div>   
            </div>
        </div>
    </div>
</div>


<app-footer-style-two></app-footer-style-two>
<app-preloader *ngIf="loading" ></app-preloader>
