<app-navbar-style-two></app-navbar-style-two>



<div class="banner-area-two">
    <div class="container">
        <div class="banner-content">
            <h1>Explora, Compite, Conéctate</h1>
            <p>Encuentra eventos de ciclismo y participa en experiencias inolvidables.</p>
            <div class="search-box">
                   
                <div class="form-search">
                    <div class="form-inner">
                        <div class="form-group search-info">
                            <!-- <i class="fa fa-sticky-note  bficon"></i> -->
                            <i class='bx bxs-check-square'></i>
                            <input type="text" [(ngModel)]="nombre" class="form-control" placeholder="Nombre">
                        </div>
                        <div class="form-group search-location">
                            <!-- <i class="fas fa-map-marker-alt"></i> -->
                            <i class='bx bxs-map'></i>
                            <input type="text" [(ngModel)]="lugar" class="form-control" placeholder="Lugar">
                            <!-- <a class="current-loc-icon" href="javascript:void(0);"><i class="fas fa-crosshairs"></i></a> -->
                        </div>
                        <button (click)="buscar()" class="btn btn-primary search-btn mt-0"><i class="fas fa-search"></i> Buscar</button>
                    </div>
                </div>
            
        </div>	

            <!-- <div class="banner-btn-wrap">
                <a class="cmn-btn" routerLink="/servicios">Ver nuestros servicios</a>
            </div> -->
        </div>
    </div>
<!--    <div class="d-table">-->
<!--        <div class="d-table-cell">-->
<!--            <div class="container">-->
<!--                <div class="row align-items-center">-->
<!--                    <div class="col-lg-6" style="text-align: center;">-->
<!--                        <div class="banner-content">-->
<!--                            <h1>¿Necesitas cronometraje electrónico?</h1>-->
<!--                            <p>Revisa los servicios que tenemos para tí.</p>-->
<!--                            <div class="banner-btn-wrap">-->
<!--                                <a class="cmn-btn" routerLink="/servicios">Ver nuestros servicios</a>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->

<!--                    <div class="col-lg-6">-->
<!--                        <div class="banner-slider owl-theme owl-carousel">-->
<!--                            <div class="slider-item">-->
<!--                                <img src="assets/img/home-one/banner/manuel.png" alt="Slider">-->
<!--                            </div>-->
<!--                            <div class="slider-item">-->
<!--                                <img src="assets/img/home-one/banner/banner_web.png" alt="Slider">-->
<!--                            </div>-->
<!--                            <div class="slider-item">-->
<!--                                <img src="assets/img/home-one/banner/banner_web3.png" alt="Slider">-->
<!--                            </div>-->

<!--                            <div class="slider-item">-->
<!--                                <img src="assets/img/home-one/banner/manuel2.png" alt="Slider">-->
<!--                            </div>-->

<!--                            <div class="slider-item">-->
<!--                                <img src="assets/img/home-one/banner/manuel.png" alt="Slider">-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
</div>


<section class="collection-area pt-100 pb-100" style="overflow: hidden;">
    <div class="container" *ngIf="!loading">
        <div class="section-title">
            <h2>Encuentra tu evento</h2>
        </div>

        <div class="sorting-menu">
            <ul>
               <li #todos [ngClass]="filtro === 'TODAS' ? 'active' : ''" class="filter" (click)="filtrarEventos('TODAS')">Todos</li>
               <li #por_realizar [ngClass]="filtro === 'POR_REALIZAR' ? 'active' : ''" class="filter por_realizar" (click)="filtrarEventos('POR_REALIZAR')">Por Realizar</li>
               <li #realizados [ngClass]="filtro === 'REALIZADO' ? 'active' : ''" class="filter"  (click)="filtrarEventos('REALIZADO')">Finalizados</li>
               <!-- <li #suspendidos [ngClass]="filtro === 'SUSPENDIDO' ? 'active' : ''" class="filter"  (click)="filtrarEventos('SUSPENDIDO')">Suspendidos</li> -->
                <li #ciclismo [ngClass]="filtro === 'bike' ? 'active' : ''" class="filter"  (click)="filtrarEventos('bike')">Ciclismo</li>
                <li #running [ngClass]="filtro === 'race' ? 'active' : ''" class="filter"  (click)="filtrarEventos('race')">Trail-Running</li>

            </ul>
        </div>

        <app-evento-item [eventosFiltro] = "eventosFiltro" ></app-evento-item>

        <div class="more-collection">
            <a routerLink="/eventos">Ver más</a>
        </div>
    </div>
</section>

<section class="chef-area" style="padding: 0px 0px 20px 0px;" *ngIf="!loading">
    <div class="container">
        <!-- <div class="section-title">
            <h2>Bikerace en  🇨🇱</h2>
        </div> -->
        <app-mapa-chile [data] = "this.corredoresPorRegion" style="display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;"></app-mapa-chile>
    </div>
</section>

<section class="services-area">
    <div class="container">
        <div class="section-title">
            <ng-adsense
                [adClient]="getEnvironmentAdSense()"
                [adSlot]="4514379323"
            ></ng-adsense>
        </div>
    </div>
</section>

<section class="download-area pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="download-content">
                    <div class="section-title">
                        <span class="sub-title">Descarga</span>
                        <h2>Descarga nuestra aplicación móvil. Inscribirse a un evento nunca habia sido tan fácil</h2>
                        <p>Información de los eventos, inscripciones, podios, cambios de categorías y más.</p>
                    </div>

                    <ul>
                        <li style="text-align: center;"><span>01</span> Busca tu evento favorito</li>
                        <li style="text-align: center;"><span>02</span> Ve la información del evento</li>
                        <li style="text-align: center;"><span>03</span> Inscríbete!</li>
                    </ul>

                    <div class="app-wrap" style="text-align: center;">
                        <a target="_blank" href="https://play.google.com/store/apps/details?id=cl.bikerace"><img src="assets/img/home-one/google-store.png" alt="Google"></a>
                        <a (click) = "clickAppStore()"><img src="assets/img/home-one/app-store.png" alt="App"></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="download-img">
                    <img src="assets/img/home-one/telefono.png" alt="Download">
                </div>
            </div>
        </div>
    </div>
</section>


<div class="contact-form-area ptb-100" >
    <div class="container">

        <div class="section-title">
            <h2 style="color:white">¿Quieres una cotización?</h2>
        </div>

        <div class="row">
            <div class="col-lg-6">
                <div class="contact-item" >
                    <form id="contactForm" [formGroup]="formCotizacion">
                        <div class="row">
                            <div class="col-sm-6 col-lg-12">
                                <div class="form-group">
                                    <input type="text" formControlName="nombre" name="name" id="name" class="form-control" required placeholder="Nombre">
                                    <span *ngIf="formCotizacion.controls['nombre'].invalid && (formCotizacion.controls['nombre'].dirty || formCotizacion.controls['nombre'].touched)" style="color:red"><b>El campo nombre es obligatorio.</b></span>

                                </div>
                            </div>

                            <div class="col-sm-6 col-lg-12">
                                <div class="form-group">
                                    <input type="email" formControlName="correoElectronico" name="email" id="email" class="form-control" required placeholder="Correo electrónico">
                                    <span *ngIf="formCotizacion.controls['correoElectronico'].invalid && (formCotizacion.controls['correoElectronico'].dirty || formCotizacion.controls['correoElectronico'].touched)" style="color:red"><b>El campo correo electrónico es obligatorio.</b></span>

                                </div>
                            </div>

                            <div class="col-sm-6 col-lg-12">
                                <div class="form-group">
                                    <input type="text" formControlName="numeroTelefono" name="phone_number" id="phone_number" required class="form-control" placeholder="Número de teléfono">
                                    <span *ngIf="formCotizacion.controls['numeroTelefono'].invalid && (formCotizacion.controls['numeroTelefono'].dirty || formCotizacion.controls['numeroTelefono'].touched)" style="color:red"><b>El campo número de teléfono es obligatorio.</b></span>

                                </div>
                            </div>

                            <div class="col-sm-6 col-lg-12">
                                <div class="form-group">
                                    <input type="number" formControlName="numeroDeportistas" name="cantidad_corredores" id="cantidad_corredores" required class="form-control" placeholder="Número aprox. de deportistas">
                                    <span *ngIf="formCotizacion.controls['numeroDeportistas'].invalid && (formCotizacion.controls['numeroDeportistas'].dirty || formCotizacion.controls['numeroDeportistas'].touched)" style="color:red"><b>El campo número de deportistas es obligatorio.</b></span>

                                </div>
                            </div>

                            <div class="col-sm-6 col-lg-12">
                                <div class="form-group">
                                    <input type="text" formControlName="lugarEvento" name="lugar" id="lugar" class="form-control" required placeholder="Lugar realización del evento">
                                    <span *ngIf="formCotizacion.controls['lugarEvento'].invalid && (formCotizacion.controls['lugarEvento'].dirty || formCotizacion.controls['lugarEvento'].touched)" style="color:red"><b>El campo lugar del evento es obligatorio.</b></span>

                                </div>
                            </div>

                            <div class="col-sm-6 col-lg-12">
                                <div class="form-group">
                                    <input type="text" formControlName="fechaEvento" name="fecha" id="fecha" class="form-control" required placeholder="Fecha estimada del evento">
                                    <span *ngIf="formCotizacion.controls['fechaEvento'].invalid && (formCotizacion.controls['fechaEvento'].dirty || formCotizacion.controls['fechaEvento'].touched)" style="color:red"><b>El campo fecha del evento es obligatorio.</b></span>

                                </div>
                            </div>

                            <div class="col-md-12 col-lg-12">
                                <div class="form-group">
                                    <textarea name="message" formControlName="comentarios" class="form-control" id="message" cols="30" rows="6" required placeholder="Comentario"></textarea>
                                    <span *ngIf="formCotizacion.controls['comentarios'].invalid && (formCotizacion.controls['comentarios'].dirty || formCotizacion.controls['comentarios'].touched)" style="color:red"><b>El campo comentarios es obligatorio.</b></span>

                                </div>
                            </div>

                            <div class="col-sm-6 col-lg-12" style="text-align: -webkit-center;">
                                <div class="form-group">
                                    <re-captcha (resolved)="checkCaptcha($event)" formControlName="token" id="recaptcha" ></re-captcha>
                                    <span *ngIf="formCotizacion.controls['token'].invalid && (formCotizacion.controls['token'].dirty || formCotizacion.controls['token'].touched)" style="color:red"><b>Este campo es obligatorio.</b></span>


                                </div>
                            </div>

                            <div class="col-md-12 col-lg-12" style="text-align: center;">
                                <button type="button" (click)="solicitarCotizacion()" class="cmn-btn btn">Solicitar cotización</button>
                            </div>
                        </div>
                    </form>

                </div>
            </div>

            <div class="col-lg-6">
                <div class="contact-img">
                    <img src="assets/img/manuelfelipe.png" alt="Contact">
                </div>
            </div>
        </div>
    </div>
</div>

<section class="chef-area" style="padding-top: 30px;" *ngIf="topmasculino.length>0" >
    <div class="container">
        <div class="section-title">
            <h2>Deportistas TOP Masculinos</h2>
            <p>De acuerdo de los resultados cronometrados y subidos a la plataforma Bikerace, los deportistas top masculinos son los siguientes:</p>
        </div>

        <div class="row">
            <div class="col-sm-6 col-lg-3" *ngFor="let corredor of topmasculino">
                <div class="chef-item">
                    <div class="chef-top">
                        <img *ngIf="corredor.photoProfile === null" style="width:306px; height: 306px; object-fit: cover" src="https://firebasestorage.googleapis.com/v0/b/bikerace-prod.appspot.com/o/147144.png?alt=media&token=34eed580-0d28-4e7b-89e4-1a9fdd7b0612" alt="Chef">
                        <img *ngIf="corredor.photoProfile !== null" style="width:306px; height: 306px; object-fit: cover" [src]="corredor.photoProfile" alt="Chef">
                        <div class="chef-inner">
                            <h3>{{camelCaseToWords(corredor.nombre)}}</h3>
                            <span>{{camelCaseToWords(corredor.apellido)}}</span>
                        </div>
                    </div>
                    <div class="chef-bottom">
<!--                        <ul>-->
<!--                            <li><a target="_blank" href="https://es-la.facebook.com/trekmdrchillan/"><i class='bx bxl-facebook'></i></a></li>-->
<!--                            <li><a target="_blank" href="https://mdr-trek-chillan.negocio.site/"><i class='bx bx-laptop'></i></a></li>-->
<!--                            <li><a target="_blank" href="https://www.instagram.com/mdr_bicicletas/"><i class='bx bxl-instagram'></i></a></li>-->
<!--                        </ul>-->
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>

<section class="chef-area" style="padding-top: 30px;" *ngIf="topfemenino.length>0">
    <div class="container">
        <div class="section-title">
            <h2>Deportistas TOP Femeninas</h2>
            <p>De acuerdo de los resultados cronometrados y subidos a la plataforma Bikerace, las deportistas top femeninas son las siguientes:</p>
        </div>

        <div class="row">
            <div class="col-sm-6 col-lg-3" *ngFor="let corredor of topfemenino">
                <div class="chef-item">
                    <div class="chef-top">
                        <img *ngIf="corredor.photoProfile === null" style="width:306px; height: 306px; object-fit: cover" src="https://firebasestorage.googleapis.com/v0/b/bikerace-prod.appspot.com/o/219969.png?alt=media&token=00886427-1675-442b-85a7-1ba4c83760ad" alt="Chef">
                        <img *ngIf="corredor.photoProfile !== null" style="width:306px; height: 306px; object-fit: cover" [src]="corredor.photoProfile" alt="Chef">
                        <div class="chef-inner">
                            <h3>{{camelCaseToWords(corredor.nombre)}}</h3>
                            <span>{{camelCaseToWords(corredor.apellido)}}</span>
                        </div>
                    </div>
                    <div class="chef-bottom">
<!--                        <ul>-->
<!--                            <li><a target="_blank" href="https://es-la.facebook.com/trekmdrchillan/"><i class='bx bxl-facebook'></i></a></li>-->
<!--                            <li><a target="_blank" href="https://mdr-trek-chillan.negocio.site/"><i class='bx bx-laptop'></i></a></li>-->
<!--                            <li><a target="_blank" href="https://www.instagram.com/mdr_bicicletas/"><i class='bx bxl-instagram'></i></a></li>-->
<!--                        </ul>-->
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>

<!-- <div class="about-area-two pt-100 pb-70" >

    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="about-img">
                    <img src="assets/img/home-one/video.png" alt="About">
                    <img src="assets/img/home-three/about2.png" alt="About">

                    <div class="video-wrap">
                        <a href="https://www.youtube.com/watch?v=azq-BViaf6w" class="popup-youtube"><i class='bx bx-play'></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="about-content">
                    <div class="section-title">
                        <h2>1er Desafío XCM BIKERACE 2022</h2>
                        <p style="text-align: justify">
                            El día de domingo 14/08, realizamos nuestro primer evento, y adicionalmente inauguramos masivamente el sistema de cronometraje electrónico. Ese era nuestro objetivo, por ello la ilusión, la adquisición a bajo costo para que muchos se animaran a participar; queríamos que todo fuese perfecto, es por lo que trabajamos, pusimos el corazón en ello; en detalles que hacen la diferencia y que entregan valor humano.
                            Nos esforzamos en los preparativos para estar a la altura de nuestros desafíos; ahora nuestro objetivo es seguir mejorando, impulsando nuestra marca como pioneros de la región de Ñuble. Pues sabemos qué significa la tenacidad, la perseverancia, la disciplina, esa garra sobre humana que nos habita, y Bikerace es eso para nosotros, nuestro más claro desafío.
                        </p>
                    </div>
                    <a class="cmn-btn" routerLink="/evento" [queryParams]="{ id: 9}" >Ver más</a>
                </div>
            </div>
        </div>
    </div>
</div> -->

<section class="services-area ptb-100" >
    <div class="container">
        <div class="section-title">
            <h2>¿Qué dicen acerca de Bikerace?</h2>
        </div>

        <div class="services-slider owl-theme owl-carousel">
<!--            <div class="services-item">-->
            <div class="blog-item">
                <div class="blog-top">
                    <!--                        <a routerLink="/blog-detailhtml">-->
                    <img src="assets/img/home-one/miguelangel.jpeg" alt="Blog">
                    <!--                        </a>-->
                    <span>01 Diciembre 2022</span>
                </div>
                <div class="blog-bottom">
                    <!--                    <h3>Francisco Balboa Mariangel - Presidente Deferación de Ciclismo Chillán</h3>-->
                    <p>"Primero que todo felicitar a Bikerace, por el excelente trabajo realizado en el 'Décimo segunda
                        Versión de Desafío Achibueno 2022', 0 problema en los resultados, como también rapidez en tener a los premiados, un gran acierto poder haber contado con un trabajo profesional y comprometido.
                        Ayudó a tener un Achibueno redondito. Solo instarlos a seguir en esta senda que es vital para el desarrollo de las actividades.
                        Pilar fundamental. Abrazo fraterno"</p>
                </div>
            </div>

            <div class="blog-item">
                <div class="blog-top">
                    <!--                        <a routerLink="/blog-detailhtml">-->
                    <img src="assets/img/home-one/francisco.jpeg" alt="Blog">
                    <!--                        </a>-->
                    <span>27 Octubre 2022</span>
                </div>
                <div class="blog-bottom">
<!--                    <h3>Francisco Balboa Mariangel - Presidente Deferación de Ciclismo Chillán</h3>-->
                    <p>"La página es muy amigable al usuario, es muy bueno que tengamos este servicio de cronometraje electrónico en nuestra región; fue un gran acierto de BIKERACE para los que organizamos competencias. Felicidades por esta gran herramienta!".</p>
                </div>
            </div>

            <div class="blog-item">
                <div class="blog-top">
                    <!--                        <a routerLink="/blog-detailhtml">-->
                    <img src="assets/img/home-one/felipe.jpeg" alt="Blog">
                    <!--                        </a>-->
                    <span>27 Octubre 2022</span>
                </div>
                <div class="blog-bottom">
<!--                    <h3>Felipe González - Presidente Club de ciclismo Riders Ñuble</h3>-->
                    <p>"Nos alegra informar nuestra experiencia satisfactoria como usuarios frecuentes en competencias de ciclismo a nivel regional del proyecto Bike Race, un emprendimiento que otorga gran seguridad con algo tan delicado que es el cronometraje a nivel competitivo.

                        Mis sinceras palabras de felicitaciones por crear una empresa sólida que demuestra profesionalismo en cada aspecto de sus servicios.

                        Muchas felicidades y éxito en sus nuevos desafíos".</p>
                </div>
            </div>

            <div class="blog-item">
                <div class="blog-top">
                    <!--                        <a routerLink="/blog-detailhtml">-->
                    <img src="assets/img/home-one/yenny.jpeg" alt="Blog">
                    <!--                        </a>-->
                    <span>24 Octubre 2022</span>
                </div>
                <div class="blog-bottom">
<!--                    <h3>Yeny Reyes Millar - Presidenta Pedaleras Ñuble</h3>-->
                    <p>"Excelente cronometraje, rápido , plataforma muy fácil de utilizar,comunicación fluida responden los correos muy rápido y ante cualquier duda , la mejor plataforma de cronometraje. Súper recomendable."</p>
                </div>
            </div>

            <div class="blog-item">
                <div class="blog-top">
                    <!--                        <a routerLink="/blog-detailhtml">-->
                    <img src="assets/img/home-one/rodrigo.jpeg" alt="Blog">
                    <!--                        </a>-->
                    <span>21 Octubre 2022</span>
                </div>
                <div class="blog-bottom">
<!--                    <h3>Rodrigo Mundaca del Río - MDR Mundo dos ruedas</h3>-->
                    <p>"Hemos confiando en BikeRace desde sus inicios, es una empresa creada por deportista que viven el ciclismo desde adentro conociendo las necesidades de quienes organizamos actividades y de quienes participan y/o compiten en ellas.</p>
                </div>
            </div>

            <div class="blog-item">
                <div class="blog-top">
                    <!--                        <a routerLink="/blog-detailhtml">-->
                    <img src="assets/img/home-one/nicolas.jpeg" alt="Blog">
                    <!--                        </a>-->
                    <span>21 Octubre 2022</span>
                </div>
                <div class="blog-bottom">
<!--                    <h3>Nicolás Navarrete - Encargado de Deportes - I. Municipalidad Pinto</h3>-->
                    <p>"Excelente servicio, en lo técnico y en lo humano donde realmente se pasaron. Es un equipo que
                        tiene toda la disposición a mejorar los eventos y servicios, dispuestos a resolver problemas emergentes
                        de planificación, todo con la intensión de siempre construir. Esta experiencia de poder contar con sus
                        servicios fue puros buenos puntos, ya que nos brindan una confianza que de verdad te hacen confiar 😅 en lo
                        que hacen, desarrollando un trabajo de gran calidad 👌. Bikerace, realmente se pasaron...!! Y lo mejor talento local #ÑUBLE"</p>
                </div>
            </div>

            <div class="blog-item">
                <div class="blog-top">
                    <!--                        <a routerLink="/blog-detailhtml">-->
                    <img src="assets/img/home-one/nicole.jpeg" alt="Blog">
                    <!--                        </a>-->
                    <span>21 Octubre 2022</span>
                </div>
                <div class="blog-bottom">
<!--                    <h3>Nicole Echeverría Rodríguez - Deportista y profesora</h3>-->
                    <p>"La experiencia trabajando con el equipo de bike race fue simplemente espectacular, si tuviera que decir solo una palabra de ellos sería profesionalismo. Siempre dispuestos a solucionar dudas a nosotros como organizadores o inquietudes de los mismos corredores, la comunicación es súper expedita, además, su plataforma es súper amigable lo que hace que el organizador y corredor sepan manejarla súper bien."</p>
                </div>
            </div>

<!--            <div class="blog-item">-->
<!--                <div class="blog-top">-->
<!--                    &lt;!&ndash;                        <a routerLink="/blog-detailhtml">&ndash;&gt;-->
<!--                    <img src="assets/img/home-one/pabloaraya.png" alt="Blog">-->
<!--                    &lt;!&ndash;                        </a>&ndash;&gt;-->
<!--                    <span>05 Mayo 2022</span>-->
<!--                </div>-->
<!--                <div class="blog-bottom">-->
<!--                    <h3>Pablo Araya - Deportista</h3>-->
<!--                    <p>"La plataforma es genial, todo muy amigable, una excelente página, todo muy rápido, es muy fácil-->
<!--                        interactuar en ella, 10 de 10, 100% recomendable, se nota que hay dedicación, cariño y esfuerzo-->
<!--                        detrás, un abrazo sigan por ese camino 👌"</p>-->
<!--                </div>-->
<!--            </div>-->
<!--            <div class="blog-item">-->
<!--                <div class="blog-top">-->
<!--                    <img src="assets/img/home-one/danielriquelme.png" alt="Blog">-->
<!--                    <span>05 Mayo 2022</span>-->
<!--                </div>-->
<!--                <div class="blog-bottom">-->
<!--                    <h3>Daniel Riquelme - Deportista</h3>-->
<!--                    <p>"La plataforma es súper sencilla, fácil de entender y super bien explicado, también cuenta con factibilidad de pago-->
<!--                        con tarjetas como crédito, débito y sencillito, excelente plataforma, le doy un 7👌"</p>-->
<!--                </div>-->
<!--            </div>-->
<!--            <div class="blog-item">-->
<!--                <div class="blog-top">-->
<!--                    <img src="assets/img/home-one/alecid.png" alt="Blog">-->
<!--                    <span>05 Mayo 2022</span>-->
<!--                </div>-->
<!--                <div class="blog-bottom">-->
<!--                    <h3>Alejandro Cid - Deportista</h3>-->
<!--                    <p>"Excelente plataforma, entretenida e intuitiva, con múltiples opciones de pago y cuando necesite ayuda,-->
<!--                        los chicos de Bikerace no tardaron en contestarme y asesorarme"</p>-->
<!--                </div>-->
<!--            </div>-->
<!--            <div class="blog-item">-->
<!--                <div class="blog-top">-->
<!--                    &lt;!&ndash;                        <a routerLink="/blog-detailhtml">&ndash;&gt;-->
<!--                    <img src="assets/img/home-one/josecaro.png" alt="Blog">-->
<!--                    &lt;!&ndash;                        </a>&ndash;&gt;-->
<!--                    <span>30 Marzo 2022</span>-->
<!--                </div>-->
<!--                <div class="blog-bottom">-->
<!--                    <h3>José Caro Allende - Deportista</h3>-->
<!--                    <p>"Cero problema con la página para el pago, rápido, seguro y te envía directamente a mercado pago 👌"</p>-->
<!--                    &lt;!&ndash;                        <a class="cmn-btn" routerLink="/blog-detailhtml">Read More</a>&ndash;&gt;-->
<!--                </div>-->
<!--            </div>-->
<!--            <div class="blog-item">-->
<!--                    <div class="blog-top">-->
<!--                        &lt;!&ndash;                        <a routerLink="/blog-detailhtml">&ndash;&gt;-->
<!--                        <img src="assets/img/home-one/pedaleras.jpg" alt="Blog">-->
<!--                        &lt;!&ndash;                        </a>&ndash;&gt;-->
<!--                        <span>28 Febrero 2022</span>-->
<!--                    </div>-->
<!--                    <div class="blog-bottom">-->
<!--                        <h3>Pedaleras de Ñuble - Deportista</h3>-->
<!--                        <p>"Excelente plataforma de planificación de eventos, hace notas el profesionalismo, dedicación y transparencia realmente recomendable al 1000%"</p>-->
<!--                        &lt;!&ndash;                        <a class="cmn-btn" routerLink="/blog-detailhtml">Read More</a>&ndash;&gt;-->
<!--                    </div>-->
<!--                </div>-->
<!--            <div class="blog-item">-->
<!--                <div class="blog-top">-->
<!--                    &lt;!&ndash;                        <a routerLink="/blog-detailhtml">&ndash;&gt;-->
<!--                    <img src="assets/img/home-one/jonatan250.jpg" alt="Blog">-->
<!--                    &lt;!&ndash;                        </a>&ndash;&gt;-->
<!--                    <span>27 Febrero 2022</span>-->
<!--                </div>-->
<!--                <div class="blog-bottom">-->
<!--                    <h3>Jonathan Cares - Deportista</h3>-->
<!--                    <p>"Una excelente herramienta de estadísticas y muy recomendable por sus buenos resultados"</p>-->
<!--                    &lt;!&ndash;                        <a class="cmn-btn" routerLink="/blog-detailhtml">Read More</a>&ndash;&gt;-->
<!--                </div>-->
<!--            </div>-->


        </div>
    </div>
</section>

<section class="chef-area" style="padding-top: 30px;" *ngIf="auspiciadores.length > 0">
    <div class="container">
        <div class="section-title">
            <h2>Nuestros Auspiciadores</h2>
<!--            <p>Ofrecemos el mejor servicio, siempre comprometidos a que sea una excelente experiencia. Te invitamos a unirte a los mejores!!</p>-->
        </div>

        <div class="row">
            <div class="col-sm-6 col-lg-3" *ngFor="let auspiciador of auspiciadores">
                <div class="chef-item">
                    <div class="chef-top">
                        <img [src]="auspiciador.image_url" alt="Chef">
                        <div class="chef-inner">
                            <h3>{{auspiciador.titulo}}</h3>
                           <span>{{auspiciador.subtitulo}}</span>
                        </div>
                    </div>
                    <div class="chef-bottom">
                        <ul>
                            <li *ngIf="auspiciador.facebook_url !== null" ><a target="_blank" [href]="auspiciador.facebook_url"><i class='bx bxl-facebook'></i></a></li>
                            <li *ngIf="auspiciador.instagram_url !== null"><a target="_blank" [href]="auspiciador.instagram_url"><i class='bx bxl-instagram'></i></a></li>  
                            <li *ngIf="auspiciador.pagina_web_url !== null"><a target="_blank" [href]="auspiciador.pagina_web_url"><i class='bx bx-laptop'></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="chef-area" style="padding-top: 30px;" *ngIf="clientes.length > 0" >
    <div class="container">
        <div class="section-title">
            <h2>Nuestros Clientes</h2>
            <p>Ofrecemos el mejor servicio, siempre comprometidos a que sea una excelente experiencia. Te invitamos a unirte a los mejores!!</p>
        </div>

        <div class="row">
            <div class="col-sm-4 col-lg-3" *ngFor="let cliente of clientes">
                <div class="chef-item">
                    <div class="chef-top">
                        <img [src]="cliente.image_url" alt="Chef">
                        <div class="chef-inner">
                            <h3>{{cliente.titulo}}</h3>
                           <span>{{cliente.subtitulo}}</span>
                        </div>
                    </div>
                    <div class="chef-bottom">
                        <ul>
                            <li *ngIf="cliente.facebook_url !== null" ><a target="_blank" [href]="cliente.facebook_url"><i class='bx bxl-facebook'></i></a></li>
                            <li *ngIf="cliente.instagram_url !== null"><a target="_blank" [href]="cliente.instagram_url"><i class='bx bxl-instagram'></i></a></li>  
                            <li *ngIf="cliente.pagina_web_url !== null"><a target="_blank" [href]="cliente.pagina_web_url"><i class='bx bx-laptop'></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--<section class="subscribe-area">-->
<!--    <div class="subscribe-shape"><img src="assets/img/home-one/reservation-shape.png" alt="Shape"></div>-->

<!--    <div class="container">-->
<!--        <div class="row align-items-center">-->
<!--            <div class="col-lg-7">-->
<!--                <div class="subscribe-item">-->
<!--                    <div class="section-title">-->
<!--                        <h2>Subscribe News Letter for Get Update</h2>-->
<!--                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do tempor incididunt ut labore et dolore magna aliqua.</p>-->
<!--                    </div>-->

<!--                    <form class="newsletter-form" data-toggle="validator">-->
<!--                        <input type="email" class="form-control" placeholder="Enter your email" name="EMAIL" required autocomplete="off">-->
<!--                        <button class="btn cmn-btn" type="submit">Subscribe</button>-->
<!--                    </form>-->

<!--                    <div class="social-link">-->
<!--                        <ul>-->
<!--                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>-->
<!--                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>-->
<!--                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>-->
<!--                            <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li>-->
<!--                        </ul>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->

<!--            <div class="col-lg-5">-->
<!--                <div class="subscribe-img">-->
<!--                    <img src="assets/img/home-one/subscribe-main.png" alt="Subscribe">-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
<!--</section>-->
<br>
<br>
<br>
<app-footer-style-two></app-footer-style-two>

<app-preloader *ngIf="loading" ></app-preloader>

