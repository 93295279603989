import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { RutValidator } from 'ng9-rut';
import { firstValueFrom, forkJoin, Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { CalificacionService } from 'src/app/services/calificacion.service';
import Swal from 'sweetalert2';
import { EventoService } from '../../../services/evento.service';

@Component({
    selector: 'app-calificaciones',
    templateUrl: './calificaciones.component.html',
    styleUrls: ['./calificaciones.component.scss']
})
export class CalificacionesComponent implements OnInit {

    public loading = false;
    private compDestroy: Subject<boolean> = new Subject();

    public calificaciones: any[] = [];
    public id : number = 0;

    constructor(
        private calificacionService: CalificacionService,
        private activatedroute: ActivatedRoute,
    ) {
        this.activatedroute.queryParams.subscribe(params => {
            this.id = Number(params.id);
        });
    }

    ngOnInit(): void {
        this.obtenerCalificaciones();
    }


    

    

    obtenerCalificaciones() {        
        this.loading = true;
        const obtenerCalificaciones: Observable<any> = this.calificacionService.obtenerCalificaciones(this.id, 'PUBLICADO');

        const petitions = forkJoin([obtenerCalificaciones]);

        petitions.pipe(take(1), takeUntil(this.compDestroy))
            .subscribe(
                res => {
                    this.calificaciones = res[0];
                    this.loading = false;

                },
                err => {
                    if (err) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: JSON.parse(err.error).message,
                        })

                    }
                    this.loading = false;
                });
    }

    obtenerIniciales(calificacion){
        return `${calificacion.corredor.nombre.toUpperCase()[0]}${calificacion.corredor.apellido.toUpperCase()[0]}`;
    }

    contarCantidadCalificaciones(calificacionP:number){
        return this.calificaciones.filter((calificacion) => calificacion.calificacion === calificacionP).length;
    }
}
