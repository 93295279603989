import { Component, OnInit, TemplateRef } from '@angular/core';
import { forkJoin, Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { ResultadoService } from '../../../services/resultado.service';
import { take, takeUntil } from 'rxjs/operators';
import { CorredorService } from '../../../services/corredor.service';
import { EventoService } from '../../../services/evento.service';
import { GoogleAnalytics } from '../../../shared/google-analytics';
import { CategoriaService } from '../../../services/categoria.service';

@Component({
    selector: 'app-inscriptions-events',
    templateUrl: './inscriptions-events.component.html',
    styleUrls: ['./inscriptions-events.component.scss']
})
export class InscriptionsEventsComponent implements OnInit {
    public id = 0;
    private compDestroy: Subject<boolean> = new Subject();
    public loading = false;
    public corredores: any[] = [];
    public categoriasConCorredor: any[] = [];
    public categoriasConCorredorFilter: any[] = [];
    public corredoresFilter: any[] = [];
    public search: string;
    public searchCategoria = '0';
    public categorias: any = [];
    public evento;

    constructor(
        private activatedroute: ActivatedRoute,
        private corredorService: CorredorService,
        private eventoService: EventoService,
        private googleAnalytics: GoogleAnalytics,
        private categoriaService: CategoriaService
    ) {
        this.activatedroute.queryParams.subscribe(params => {
            if (params.id !== undefined) {
                this.id = Number(params.id);

            }
        });
    }

    ngOnInit(): void {
        this.googleAnalytics.eventEmitter('listado-inscritos', 'exitoso', '', 'click', 0);

        this.eventoService.observableData$
            .subscribe(
                res => {
                    if (res) {
                        this.getDataDefault();
                    }
                });

        this.getCategorias();
    }

    getCategorias() {
        this.loading = true;
        let petition = null;

        petition = this.categoriaService.obtenerCategoriaByEventoId(this.id);

        const petitions = forkJoin([petition]);

        petitions
            .pipe(take(1), takeUntil(this.compDestroy))
            .subscribe(
                (res: any[]) => {
                    this.categorias = res[0];
                    this.getDataDefault();
                },
                err => {
                    console.log(err);
                    this.loading = false;
                });
    }

    getDataDefault() {
        this.loading = true;
        const petition = this.eventoService.obtenerEventoSimplifyById(this.id);


        const petitions = forkJoin([petition]);

        petitions
            .pipe(take(1), takeUntil(this.compDestroy))
            .subscribe(
                (res: any[]) => {
                    this.evento = res[0];
                    if (this.evento.publicar_inscritos === 'PUBLICAR') {
                        this.getDataCorredores();
                    }
                    this.loading = false;
                },
                err => {
                    console.log(err);
                    this.loading = false;
                });
    }

    getDataCorredores() {
        this.loading = true;
        const petition = this.corredorService.obtenerCorredorPorEventoSimplify(this.id);


        const petitions = forkJoin([petition]);

        petitions
            .pipe(take(1), takeUntil(this.compDestroy))
            .subscribe(
                (res: any[]) => {
                    res[0].forEach((element: any) => {
                        this.corredores.push(element);
                    });

                    this.corredoresFilter = this.corredores;
                    this.clasificarPorCategoria();
                    this.procesarFederados();
                    this.categoriasConCorredorFilter = this.categoriasConCorredor;

                    this.categoriasConCorredorFilter = this.categoriasConCorredorFilter.sort((a, b) => a.nombre > b.nombre ? 1 : -1);

                    this.loading = false;
                },
                err => {
                    console.log(err);
                    this.loading = false;
                });
    }

    changeSearch() {
        this.clasificarPorCategoria();
        this.procesarFederados();
        this.categoriasConCorredorFilter = [];
        if (this.search.toLowerCase().length === 0) {
            this.categoriasConCorredorFilter = [].concat(this.categoriasConCorredor);
            this.categoriasConCorredorFilter = this.categoriasConCorredorFilter.sort((a, b) => a.nombre > b.nombre ? 1 : -1);

        } else {
            this.categoriasConCorredorFilter = [].concat(this.categoriasConCorredor.filter((categoria) =>
                categoria.corredores.filter((corredor) =>
                    (corredor.nombre.toLowerCase() + ' ' + corredor.apellido.toLowerCase()).includes(this.search.toLowerCase())).length > 0));

            this.categoriasConCorredorFilter.forEach((categoria) => {
                categoria.corredores = [].concat(categoria.corredores.filter((corredor) => (corredor.nombre.toLowerCase() + ' ' + corredor.apellido.toLowerCase()).includes(this.search.toLowerCase())));
            });
            this.categoriasConCorredorFilter = this.categoriasConCorredorFilter.sort((a, b) => a.nombre > b.nombre ? 1 : -1);

        }

    }

    clasificarPorCategoria() {
        this.categoriasConCorredor = [];

        this.categorias.forEach((categoria) => {
            
            this.corredores.filter((corredorFilter) => corredorFilter.categoria === categoria.id).forEach((corredor) => {
                if (!this.categoriasConCorredor.find((categoriaFind) => categoriaFind.id === corredor.categoria)) {
                    this.categoriasConCorredor.push({
                        id: categoria.id,
                        nombre: categoria.nombre,
                        modo: categoria.modo,
                        desafio: categoria.desafioEntity.nombre,
                        federada: categoria.federada === 'ACTIVADA' ? 'Federada' : 'No federada',
                        sexo: categoria.sexo,
                        corredores: [
                            corredor
                        ],
                    });
                } else {
                    this.categoriasConCorredor.find((categoriaFind) => categoriaFind.id === corredor.categoria).corredores.push(corredor);

                }
            });

        });


    }

    procesarFederados() {
        if(this.evento.opcion_federado === 'FEDERADO'){
            this.categoriasConCorredor.forEach((categoria) => {
                if (categoria.federada === 'Federada') {
                    const categoriaFederada = this.categoriasConCorredor.find((categoriaFind) => categoriaFind.nombre === categoria.nombre && categoriaFind.modo === categoria.modo && categoriaFind.sexo === categoria.sexo && categoriaFind.federada === 'Federada');
                    if (categoriaFederada !== undefined) {
                        categoriaFederada.corredores = categoriaFederada.corredores.concat(categoria.corredores);
                    } else {
                        this.categoriasConCorredor.push({
                            id: categoria.id,
                            nombre: categoria.nombre,
                            modo: categoria.modo,
                            desafio: categoria.desafioEntity.nombre,
                            federada: 'Federada',
                            sexo: categoria.sexo,
                            corredores: categoria.corredores,
                        });
                    }
                }
            });
        }

        if(this.evento.opcion_federado === 'NO_FEDERADO' || this.evento.opcion_federado === 'AMBAS_OPCIONES'){
            this.categoriasConCorredor.forEach((categoria) => {

                if (categoria.federada === 'Federada') {
                    const categoriaNoFederada = this.categoriasConCorredor.find((categoriaFind) => categoriaFind.nombre === categoria.nombre && categoriaFind.modo === categoria.modo && categoriaFind.sexo === categoria.sexo && categoriaFind.federada === 'No federada');
                    if (categoriaNoFederada !== undefined) {
                        categoriaNoFederada.corredores = categoriaNoFederada.corredores.concat(categoria.corredores);
                    } else {
                        this.categoriasConCorredor.push({
                            id: categoria.id,
                            nombre: categoria.nombre,
                            modo: categoria.modo,
                            desafio: categoria.desafio,
                            federada: 'No federada',
                            sexo: categoria.sexo,
                            corredores: categoria.corredores,
                        });
                    }
                }
            });
        }


       
    }

    changeSearchCategoria() {
        this.search = '';
        if (this.searchCategoria === '0') {
            this.categoriasConCorredorFilter = Object.assign([], this.categoriasConCorredor);
            this.categoriasConCorredorFilter = this.categoriasConCorredorFilter.sort((a, b) => a.nombre > b.nombre ? 1 : -1);

        } else {
            this.categoriasConCorredorFilter =
                Object.assign([], this.categoriasConCorredor.filter((categoria) =>
                    `${categoria.nombre} - ${categoria.desafio} - ${categoria.modo} - ${categoria.sexo}` === this.searchCategoria
                ));
            this.categoriasConCorredorFilter = this.categoriasConCorredorFilter.sort((a, b) => a.nombre > b.nombre ? 1 : -1);

        }

    }


    validarCategoriaFederada(categoria){
        return this.categoriasConCorredorFilter.filter((cat) => cat.nombre === categoria.nombre && cat.modo === categoria.modo && cat.desafio === categoria.desafio && cat.federada === 'No federada').length === 0;
    }

}
