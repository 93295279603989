<div class="star-rating">
    <span
      *ngFor="let star of stars; let index = index"
      (mouseover)="onMouseOver(index)"
      (mouseleave)="onMouseLeave()"
      (click)="onClick(index)"
      [ngClass]="{
        'star': true,
        'filled': index < currentRating,
        'hovered': index < hoverRating,
        'small': small
      }"
    >
      ★
    </span>
  </div>