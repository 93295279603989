import { noUndefined } from '@angular/compiler/src/util';
import { Component, Input, OnInit } from '@angular/core';
import * as d3 from 'd3';

@Component({
  selector: 'app-mapa-chile',
  templateUrl: './mapa-chile.component.html',
  styleUrls: ['./mapa-chile.component.scss']
})
export class MapaChileComponent implements OnInit {
  regionSeleccionada: string = 'Región de Ñuble'; // Variable para almacenar la región seleccionada
  @Input() public data = [];
  cantDeportistas: number = 0;
  constructor(

  ) { }

  ngOnInit(): void {
    this.dibujarMapa();
    this.actualizarCantidadDeportistas();
  }

  actualizarCantidadDeportistas(){
    const regionFind = this.data.find((region)=> region.region === this.regionSeleccionada);
    this.cantDeportistas = regionFind === undefined ? 0 : regionFind.total;
  }


  dibujarMapa() {
    const width = 150; // Ancho del mapa
    const height = 750; // Alto del mapa

    // Seleccionar el contenedor del mapa
    const svg = d3.select('#mapa')
      .append('svg')
      .attr('width', width)
      .attr('height', height);

      const self = this; // Guardar la referencia del componente

    // Cargar el archivo SVG del mapa de Chile
    d3.xml('assets/img/chile.svg').then(data => {
      const svgMapa = d3.select(data.documentElement);
      svg.node().append(svgMapa.node());
      
      const regionSelect = svgMapa
        .selectAll('path')
        .filter(function() {
          return d3.select(this).attr('title') === self.regionSeleccionada;
        });

        regionSelect.style('fill', 'rgb(224, 101, 101)');

        const regionNoHabilitada = svgMapa
        .selectAll('path')
        .filter(function() {
          return self.habilitarRegion(d3.select(this).attr('title')) === false ;
        });

        regionNoHabilitada.style('fill', '#a5a4a9');


      // Configurar interacción al pasar el mouse por encima de las regiones
      svgMapa.selectAll('path')
        .on('mouseover', function () {
          if(self.habilitarRegion(d3.select(this).attr('title'))){
            
            
            svgMapa.selectAll('path') .filter(function() {
              return self.habilitarRegion(d3.select(this).attr('title')) === true ;
            }).style('fill', '#004c5a');
            
            
            d3.select(this).style('fill', 'rgb(224, 101, 101)');
            // Obtener información de la región correspondiente
            const region = d3.select(this).attr('title');
            self.regionSeleccionada = region; // Utilizar la referencia del componente
            self.actualizarCantidadDeportistas();
          }
        })
        .on('mouseout', function () {
          // d3.select(this).style('fill', '#004c5a');
          // // Limpiar información cuando el mouse sale de la región
        });
    });
  }

  habilitarRegion(regionP: string){
    const regionFind = this.data.find((region)=> region.region === regionP);
    if(regionFind===undefined){
      return false;
    }

    if(Number(regionFind.total) === 0){
      return false;
    }

    return true;

  }

}
