import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { RutValidator } from 'ng9-rut';
import { Observable, Subject, firstValueFrom, forkJoin } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { BancoService } from 'src/app/services/banco.service';
import { DevolucionService } from 'src/app/services/devolucion.service';
import { TipoCuentaService } from 'src/app/services/tipo-cuenta.service';
import Swal from 'sweetalert2';
import { EventoService } from '../../../services/evento.service';
import { PhoneMaskService } from 'src/app/shared/phone-mask';

@Component({
    selector: 'app-solicitud-devolucion',
    templateUrl: './solicitud-devolucion.component.html',
    styleUrls: ['./solicitud-devolucion.component.scss']
})
export class SolicitudDevolucionComponent implements OnInit {

    public loading = false;
    private compDestroy: Subject<boolean> = new Subject();
    public formBusqueda: FormGroup;

    public formEvento: FormGroup;
    public formDatosBancarios: FormGroup;
    public formContacto: FormGroup;

    public eventos: any[] = [];
    public solicitud;
    public extranjero = false;
    public bancos: any[] = [];
    public tipoCuentas: any[] = [];


    constructor(
        private bancoService: BancoService,
        private tipoCuentaService: TipoCuentaService,
        private eventoService: EventoService,
        private devolucion: DevolucionService,
        private fb: FormBuilder,
        private router: Router,
        private rutValidator: RutValidator,
        private phoneMask: PhoneMaskService,

    ) {

    }

    ngOnInit(): void {
        this.getData();

    }

    getData() {
        this.loading = true;
        const obtenerBancos: Observable<any> = this.bancoService.obtenerBancos();
        const obtenerTiposCuentas: Observable<any> = this.tipoCuentaService.obtenerTiposCuentas();

        const petitions = forkJoin([obtenerBancos, obtenerTiposCuentas]);

        petitions.pipe(take(1), takeUntil(this.compDestroy))
            .subscribe(
                res => {
                    this.bancos = res[0];
                    this.tipoCuentas = res[1];

                    this.initFormBusqueda();
                    this.initFormSolicitud();

                    this.loading = false;

                },
                err => {
                    if (err) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: JSON.parse(err.error).message,
                        })

                    }
                    this.loading = false;
                });
    }

    initFormBusqueda() {
        this.formBusqueda = this.fb.group({
            rut: ['', [Validators.required, this.rutValidator, Validators.minLength(5)]],
        });
    }

    initFormSolicitud() {
        this.formEvento = this.fb.group({
            evento: [null, Validators.required],
        });

        this.formDatosBancarios = this.fb.group({
            numero_cuenta: [null, Validators.required],
            nombre: [null, Validators.required],
            correo_transferencia: [null, Validators.required],
            rut_transferencia: [null, [Validators.required, this.rutValidator, Validators.minLength(5)]],
            banco: [null, Validators.required],
            tipoCuenta: [null, Validators.required],

        });

        this.formContacto = this.fb.group({
            telefono: [null, [Validators.required , this.phoneValidator]],
        });
    }

    buscarRut() {
        this.eventos = [];
        this.formContacto.reset();
        this.formDatosBancarios.reset();
        this.formEvento.reset();

        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
        });

        if (!this.formBusqueda.valid) {
            swalWithBootstrapButtons.fire(
                'Campos incompletos',
                `El campo RUT debe ser válido.`,
                'error'
            );

            return;
        }

        this.loading = true;
        const obtenerEventosPorCorredorRut: Observable<any> = this.eventoService.obtenerEventosPorCorredorRutYEstado(this.formBusqueda.get('rut').value, 'POR_REALIZAR,CANCELADO,SUSPENDIDO');

        const petitions = forkJoin([obtenerEventosPorCorredorRut]);

        petitions.pipe(take(1), takeUntil(this.compDestroy))
            .subscribe(
                res => {
                    this.eventos = res[0];
                    this.loading = false;

                },
                err => {
                    if (err) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: JSON.parse(err.error).message,
                        })

                    }
                    this.loading = false;
                });
    }



    enviarSolicitud() {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
        });

        if (!this.formContacto.valid || !this.formDatosBancarios.valid || !this.formEvento.valid) {
            swalWithBootstrapButtons.fire(
                'Campos incompletos/inválidos',
                `Todos los campos del formulario deben ser completados y válidos`,
                'error'
            );

            return;
        }

        const eventoSeleccionado = this.eventos.find((evento) => evento.id === Number(this.formEvento.get('evento').value));
        const petitionData = {
            numero_cuenta: this.formDatosBancarios.get('numero_cuenta').value,
            banco: this.bancos.find((banco) => banco.id === Number(this.formDatosBancarios.get('banco').value)),
            tipoCuenta: this.tipoCuentas.find((tipoCuenta) => tipoCuenta.id === Number(this.formDatosBancarios.get('tipoCuenta').value)),
            corredor: { id: eventoSeleccionado.corredor[0].id },
            nombre: this.formDatosBancarios.get('nombre').value,
            rut_transferencia: this.formDatosBancarios.get('rut_transferencia').value,
            correo_transferencia: this.formDatosBancarios.get('correo_transferencia').value,
            telefono: this.formContacto.get('telefono').value

        };


        this.loading = true;
        const petition: Observable<any> = this.devolucion.crearSolicitudCodigo(petitionData);

        petition
            .pipe(take(1), takeUntil(this.compDestroy))
            .subscribe(
                res => {
                    this.solicitud = res;
                    this.loading = false;
                    Swal.fire({
                        title: 'Código de confirmación',
                        text: 'Te hemos enviado un código dinámico de confirmación a tu correo electrónico, ingresalo acá para confirmar la solicitud.',
                        input: 'text',
                        inputAttributes: {
                            autocapitalize: 'off'
                        },
                        showCancelButton: true,
                        confirmButtonText: 'Confirmar',
                        cancelButtonText: 'Cancelar',
                        showLoaderOnConfirm: true,
                        preConfirm: (codigo) => {

                            return firstValueFrom(this.devolucion.validacionSolicitudCodigo(this.solicitud.id, { 'codigo': codigo })).then((data) => {
                                console.log(data);
                            }).catch((error) => {
                                console.log(error);
                                Swal.showValidationMessage(
                                    JSON.parse(error.error).message
                                );
                            })
                        },
                        allowOutsideClick: () => !Swal.isLoading()
                    }).then((result) => {
                        if (result.isConfirmed) {
                            swalWithBootstrapButtons.fire(
                                'Solicitud enviada!',
                                'Has enviado una solicitud al equipo Bikerace, pronto recibirás la respuesta de la solicitud en tu correo electrónico.',
                                'success'
                            );

                            this.router.navigate(['/']);


                        }
                    });
                },
                err => {
                    if (err) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: JSON.parse(err.error).message,
                        })

                    }
                    this.loading = false;
                });
    }

    changeExtranjero(esExtranjero: boolean) {
        this.extranjero = esExtranjero;
        this.changeTypeDocument();
    }

    changeTypeDocument() {

        switch (this.extranjero) {
            case false:
                const validator: any[] = [Validators.required, this.rutValidator, Validators.minLength(5)];
                this.formBusqueda.get('rut').setValidators(validator);
                break;
            case true:
                this.formBusqueda.get('rut').setValidators([Validators.required]);

                break;
        }
    }

    formatPhone() {
        this.formContacto.get('telefono').setValue(this.phoneMask.formatPhoneNumber(this.formContacto.get('telefono').value));
    }

    validatePhone(event) {
        return this.phoneMask.numberOnly(event);
    }

    phoneValidator(control: FormControl) {
        let phone = control.value;
        if (('' + phone).replace(/\D/g, '').length !== 11) {
            return {
                phoneInvalid: {
                    parsedPhone: ('' + phone).replace(/\D/g, '')
                }
            }
        }
        return null;
    }


  currentStep = 1;


  nextStep() {
    this.currentStep++;
  }

  prevStep() {
    this.currentStep--;
  }

  obtenerCampoEvento(){
    const eventoSeleccionado = this.eventos.find((evento) => evento.id === Number(this.formEvento.get('evento').value));
    return eventoSeleccionado === undefined ? '' :eventoSeleccionado;
  }

  obtenerCampoDatosBancarios(campo){
    switch(campo){
        case 'banco':
            const banco = this.bancos.find((banco) => banco.id === Number(this.formDatosBancarios.get('banco').value));
            return banco === undefined ? '': banco.banco;
            case 'tipoCuenta':
                const tipoCuenta = this.tipoCuentas.find((tipoCuenta) => tipoCuenta.id === Number(this.formDatosBancarios.get('tipoCuenta').value));
                return tipoCuenta=== undefined ? '' : tipoCuenta.tipoCuenta;
            default:
                return  this.formDatosBancarios.getRawValue()[campo];   
    }
  }

  obtenerCampoContacto(){
    return this.formContacto.getRawValue();
  }

}
